import React , {useState , useEffect} from 'react';
import { Link  ,useHistory } from 'react-router-dom';
import swal from 'sweetalert';


import logo1 from '../assets/images/logo-kar.png';
// import logo1 from '../assets/images/apple-touch-icon.png';
// import Head1 from "../assets/images/header-bg-rpt.jpg"
// import SearchIcon from '@material-ui/icons/Search';
// import ClearIcon from '@material-ui/icons/Clear';
// import MenuIcon from '@material-ui/icons/Menu';
// import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
// import AddAlertIcon from '@material-ui/icons/AddAlert';
// // import AddAlertIcon from '@mui/icons-material/AddAlert';
// import SendIcon from '@material-ui/icons/Send';
// import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
// import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import InstagramIcon from '@material-ui/icons/Instagram';
// import TwitterIcon from '@material-ui/icons/Twitter';
// import YouTubeIcon from '@material-ui/icons/YouTube';
// import LinkedInIcon from '@material-ui/icons/LinkedIn';
// import FavoriteIcon from '@material-ui/icons/Favorite';
import { useSelector, useDispatch } from 'react-redux';

import Loader from "react-loader-spinner";



const Header =(props)=> {

  const [total1 , settotal1] = useState(0)
    const [search , setSearch] = useState("")
    const [DataPart2 , setDataPart2] = useState([])
    // const [UserDatat , setUserDatat] = useState({})
    // const [userHeader , setuserHeader] = useState(false)
    // const [menu1 , setmenu1] = useState(false)
    // const [adminHeader , setadminHeader] = useState(false)
    // const [displayMenu , setdisplayMenu] = useState(false)
    // const [MainLine , setMainLine] = useState("")
    // const [MainForum , setMainForum] = useState(false)
    const [MainCatogories , setMainCategories] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    const [fvalue , setfvalue] = useState([])
    // const [fvalue1 , setfvalue1] = useState([])
    // const [fg , setfg] = useState(true)
    // const [abc , setabc] = useState(false)
    const [DisplaySearch , setDisplaySearch] = useState(false)
    // const [DisplayMenu , setDisplayMenu] = useState(false)
    // const [bht , setbht] = useState("")
    const [mainUser , setmainUser] = useState({})
    // const [couponShow1 , setcouponShow1] = useState(false)
    // const [name, setname] = useState("") 
    // const [email, setemail] = useState("") 
    // const [message, setmessage] = useState("") 
    // const [photo, setphoto] = useState("") 
    // const [allmessage , setallmessage] = useState([])
    const [cart1 , setcart1] = useState([])
    const [fav1 , setfav1] = useState([])
    // const [photo, setphoto] = useState("") 

    let history = useHistory();


    useEffect(() =>{
      setTimeout(()=>{
        document.getElementById("spinner").style.visibility = "hidden"
      },3000)
    },[])
  

    const changeFruit = (goto1) => {
      // setCurrentFruit(newFruit)
      // history.push(goto);
      window.location = goto1;
    }
    

    const SubmitData45342 = (e) =>{
      e.preventDefault()
      console.log("df")
      setDisplaySearch(true)
      localStorage.removeItem("SearchData")
          fetch("https://pappu-prado-main-back.vercel.app/SearchProduct",{
                                  method: "POST",
                                  headers :  {
                                      "Content-Type" : "application/json" , 
                                  } ,
                                  body : JSON.stringify({
                                      search : search.toLowerCase() ,
      
                                  })
                              })
                              .then(res=>res.json())
                              .then((res1)=>{ 
                                
                                console.log(res1) 
                                  const SearchProductItem = []
                                  
                                  let search1 = search.split(" ")
                                res1.map((res2,i)=>{
                                    // console.log(res2.Product_Name.toLowerCase().split(" "),res2.Product_Title.toLowerCase().split(" "),i)
                                    var Name = res2.Product_Name.toLowerCase().split(" ")
                                     var Title = res2.Product_Title.toLowerCase().split(" ")
                                    // console.log(Name , Title)
                                    for (let j = 0; j < search1.length; j++) {
                                      
                                      Name.map((res3,i)=>{
  
                                          if (res3 === search1[j].toLowerCase().trim()){
                                              SearchProductItem.unshift(res2)
                                          }
                                      })
                                      Title.map((res4,i)=>{
                                        if (res4 === search1[j].toLowerCase().trim()){
                                          SearchProductItem.unshift(res2)
                                        }
                                      })
                                    }
                                })
                                  console.log(SearchProductItem)    

                                  console.log(SearchProductItem,props)
                                  let uniq = [...new Set(SearchProductItem)];
      
                                  localStorage.setItem("SearchData" , JSON.stringify(uniq) )
                                  
                                  setDisplaySearch(false)
                                  setTimeout(()=>{
                                      history.push("/search/product-item");
      
                                      // return <Redirect push to="/shop/search/product-item" /> 
                                      // props.history.push(`/shop/search/product-item`)
                                  },1000)   
                                  // localStorage.setItem("SearchData" , JSON.stringify(res1) )
                                  // props.history.push(`/shop/search/product-item`)
      
                                  
                          })
                          .catch(err=>{
                            console.log(err)
                            swal("There is An Error")                  
                            setDisplaySearch(false)
                              }) 
      }

const ChangeCateUrl = (res) =>{
  console.log("sfsfd")
  history.push("/categories/"+res+"/all-products")
    
}




    
const setDisplayMenu3 =() =>{
  if(document.getElementById("menu-mobile-1").style.visibility === "hidden"){
    document.getElementById("menu-mobile-1").style.transform = "translateX(280px)"
    document.getElementById("menu-mobile-1").style.visibility = "visible"
    document.getElementById("menu-mobile-2").style.visibility = "visible"
  }
  else{
    document.getElementById("menu-mobile-1").style.transform = "translateX(280px)"
    document.getElementById("menu-mobile-1").style.visibility = "hidden"
    document.getElementById("menu-mobile-2").style.visibility = "hidden"
  }
}
        return (
            <>
             { DisplaySearch ?
                <Loader
        type="Puff"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={10000} //3 secs
        className="loader-1"
      /> 
      :""
}  
<div id="spinner" class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
              <div class="spinner-border text-primary" style={{width: "3rem", height: "3rem"}} role="status">
                  <span class="sr-only">Loading...</span>
              </div>
          </div>
  
          <div class="container-fluid topbar bg-secondary d-none d-xl-block w-100" style={{padding: "10px 0px"}}>
              <div class="container">
                  <div class="row gx-0 align-items-center" style={{height: "45px"}}>
                      <div class="col-lg-6 text-center text-lg-start mb-lg-0">
                          <div class="d-flex flex-wrap">
                              {/* <a href="https://maps.app.goo.gl/Vd8Q1jafRB2tstWp8" class="text-muted me-4"><i class="fas fa-map-marker-alt text-primary me-2"></i>Find A Location</a> */}
                              <a href="tel:+923262021001" class="text-muted me-4"><i class="fas fa-phone-alt text-primary me-2"></i>+92 326 2021001</a>
                              <a href="mailto:rentalcarspk@gmail.com" class="text-muted me-0"><i class="fas fa-envelope text-primary me-2"></i>rentalcarspk@gmail.com</a>
                          </div>
                      </div>
                      <div class="col-lg-6 text-center text-lg-end">
                          <div class="d-flex align-items-center justify-content-end">
                              <a href="https://www.facebook.com/profile.php?id=100075846142754&mibextid=ZbWKwL" class="btn-light btn-sm-square rounded-circle me-3"><i class="fab fa-facebook-f"></i></a>
                              <a href="http://tiktok.com/@sohail69366" class="btn-light btn-sm-square rounded-circle me-3"><i class="fab fa-tiktok"></i></a>
                              <a href="http://instagram.com/muhammadsohail07" class="btn-light btn-sm-square rounded-circle me-3"><i class="fab fa-instagram"></i></a>
                              <a href="https://www.linkedin.com/in/premium-rent-a-car-6bb641229" class="btn-light btn-sm-square rounded-circle me-0"><i class="fab fa-linkedin-in"></i></a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
  
          <div class="container-fluid nav-bar sticky-top px-0 px-lg-4 py-2 py-lg-0">
              <div class="container">
                  <nav class="navbar navbar-expand-lg navbar-light">
                      <Link to="/" class="navbar-brand p-0">
                          {/* <h1 class="display-6 text-primary"><i class="fas fa-car-alt me-3"></i>Cental</h1> */}
                          <img src={logo1} alt="" style={{    width: "111px",
    minHeight: "87px",
    objectFit: "inherit"}} />
                      </Link>
                      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                          <span class="fa fa-bars"></span>
                      </button>
                      <div class="collapse navbar-collapse" id="navbarCollapse">
                          <div class="navbar-nav mx-auto py-0">
                              <Link to="/" class="nav-item nav-link active">Home</Link>
                              <Link to="/about" class="nav-item nav-link">About</Link>
                              <Link to="/categories/Cars/all-products" class="nav-item nav-link">Our Fleet </Link>
                              <Link to="/service" class="nav-item nav-link">Service</Link>
                              {/* <Link to="blog.html" class="nav-item nav-link">Blog</Link> */}
                              
                              <div class="nav-item dropdown">
                                  <a to="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                                  <div class="dropdown-menu m-0">
                                      <Link to="/feature" class="dropdown-item">Our Feature</Link>
                                      <Link to="/categories/Cars/all-products" class="dropdown-item">Car Fleets</Link>
                                      <Link to="/checkout" class="dropdown-item">Booking Cart</Link>
                                      {/* <Link to="/team" class="dropdown-item">Our Team</Link> */}
                                      <Link to="/testimonial" class="dropdown-item">Testimonial</Link>
                                      {/* <Link to="/404" class="dropdown-item">404 Page</Link> */}
                                  </div>
                              </div>
                              <Link to="/contact" class="nav-item nav-link">Contact</Link>
                          </div>
                          <a href="tel:+923262021001" class="btn btn-primary rounded-pill py-2 px-4">Contact Now</a>
                      </div>
                  </nav>
              </div>
          </div>
        </>
        )
}

export default Header;