import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
// import OwlCarousel from 'react-owl-carousel';  
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";


// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";

// lymh ktzk eayl csgf

import swal from 'sweetalert';
// import Loader from "react-loader-spinner";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { Container, Card, Row, Col } from "react-bootstrap";
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import logo1 from '../assets/images/logo1.png';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";


import sli1 from '../asset/img/carousel-1.jpg';
import sli2 from '../asset/img/carousel-2.jpg';

import s1 from '../assets/images/s1.jpg';
import s2 from '../assets/images/s2.jpg';
import s3 from '../assets/images/s3.jpg';
import s4 from '../assets/images/s4.jpg';


import ab1 from '../asset/img/about-icon-1.png';
import ab2 from '../asset/img/about-icon-2.png';


import ab3 from '../asset/img/about-img.jpg';
import ab4 from '../asset/img/carousel-2-Copy.jpg';

import ban1 from '../asset/img/banner-1.jpg';

import car1 from '../asset/img/car-1.png';

import car2 from './images/x1.jpg';


// import sli3 from '../asset/images/sli3.jpg';

import sli3 from '../assets/images/LAND-CRUISER-SMK-removebg-preview.png';


const Home = ( props ) => {
   
  const options = {
    // margin: 30,
    // responsiveClass: true,
    // nav: true,
    // dots: false,
    // autoplay: false,
    // navText: ["Prev", "Next"],
    // smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 4,
        },
        1600: {
            items: 4,

        }
    },
};
const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
    const [product , setproduct] = useState([])
    const [dis , setdis] = useState(false)
    const [DataPart2 , setDataPart2] = useState([])

    const [product1 , setproduct1] = useState([])
    const [product8 , setproduct8] = useState([])
    const [product9 , setproduct9] = useState([])
    const [product10 , setproduct10] = useState([])
    const [product11 , setproduct11] = useState([])
    // const [UserDatat , setUserDatat] = useState({})
    // const [userHeader , setuserHeader] = useState(false)
    const [SliderPhoto , setSliderPhoto] = useState([])
    const [SliderPhoto8 , setSliderPhoto8] = useState([])
    // const [ArrivalPhoto , setArrivalPhoto] = useState([])
    // const [BannerPhoto , setBannerPhoto] = useState([])
    const [MainCatogories , setMainCatogories] = useState([])
    const [SubCategories , setSubCategories] = useState([])
    const [SubClassCategories , setSubClassCategories] = useState([])
    // const [DataPart2 , setDataPart2] = useState([])
    const [fvalue , setfvalue] = useState([])
    const [data, setdata] = useState([]) 
    // const [companieswith , setcompanieswith] = useState([])
    const [couponShow , setcouponShow] = useState(true)
    // const [chk1 , setchk1] = useState(true)
    // const [chk2 , setchk2] = useState(true)
    const [Cate1 , setCate1] = useState([])
    const [Cate2 , setCate2] = useState([])


    const [CarBookPrice , setCarBookPrice] = useState([])
    
    // const [Ite1 , setIte1] = useState(1)
    // const [Ite2 , setIte2] = useState(1)
    
    // const [fg , s/etfg] = useState(true)




    const [pickup1 , setpickup1] = useState("")
    const [drop1 , setdrop1] = useState("")
    const [pickup2 , setpickup2] = useState("")
    const [drop2 , setdrop2] = useState("")
    const [pickup3 , setpickup3] = useState("")
    const [drop3 , setdrop3] = useState("")
    const [pickup4 , setpickup4] = useState("")
    const [drop4 , setdrop4] = useState("")
    const [pickup , setpickup] = useState("")
    const [drop , setdrop] = useState("")
    const [choosetime , setchoosetime] = useState("")
    const [pickupdate , setpickupdate] = useState("")
    const [dropdate , setdropdate] = useState("")
    const [phone , setphone] = useState("")
    const [car , setcar] = useState("")
    const [email , setemail] = useState("")
    // const [Product_Image_Upload , setProduct_Image_Upload] = useState("")
    

    useEffect(() =>{

        // window.scrollTo(0, 0)
        setInterval(() => {
          if( document.getElementsByClassName("carousel-indicators")[0])
          document.getElementsByClassName("carousel-indicators")[0].remove()
        }, 1000);
        
 
      console.log(    document.getElementsByClassName("carousel-indicators")[0]
      )
        fetch("https://pappu-prado-main-back.vercel.app/slider-photo",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res=>res.json())
        .then(res1=>{
            console.log(res1[0],res1);
            setSliderPhoto(res1)
          })
      
        fetch("https://pappu-prado-main-back.vercel.app/MainCatogories",{
            method: "GET",
             headers :  {
             "Content-Type" : "application/json" , 
         } ,
        })
        .then(res2=>res2.json())
        .then(res3=>{
            console.log(res3);
            // setMainCatogories(res3)
            

            let dat = []
            res3.map((res55,c)=>{
                dat.push(res55)
            })
            // console.log(SubClassCategories,asse)
            console.log(dat)
            let data = dat.sort((a, b) => {
                return a.sort - b.sort;
            });
            setMainCatogories(data)
            
        })
        


        localStorage.removeItem("SearchData")
        localStorage.removeItem("Data")
        localStorage.removeItem("CartPrice")
        localStorage.removeItem("CateProduct")

  
        var data1 =  JSON.parse(localStorage.getItem("Cart")) 
       
        fetch("https://pappu-prado-main-back.vercel.app/AllProduct",{
       method: "GET",
        headers :  {
        "Content-Type" : "application/json" , 
    }
   })
   .then(res5=>res5.json())
   .then(res6=>{
    //    console.log(res6);
    const pro = []
    const pro1 = []
    const pro2 = []
    const pro3 = []
    res6.map((res7,i)=>{
        //    console.log(res7.Product_Popular );
        if ( res7.Arrival){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro.push(res7)
        }
        if ( res7.Arrival1){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro1.push(res7)
        }
        if ( res7.Arrival2){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro2.push(res7)
        }
        if ( res7.Arrival3){
            //    console.log(res7);
            //         // return setproduct1(...product1 , res3)
            pro3.push(res7)
        }
    })
    // setproduct(pro.reverse())
    setproduct8(pro)
    setproduct9(pro1)
    setproduct10(pro2)
    setproduct11(pro3)
    setproduct(res6)
    setproduct1(pro1.slice(0,8))

    setdis(true)
   })
    
 },[])

 
   
    
    
    
    

const SubmitDataMessage = (e) =>{
  e.preventDefault()
 

if(pickup===""){
  swal("Enter Pickup City")
  return
}
if(drop===""){
  swal("Enter Drop City")
  return
}

                              fetch("https://pappu-prado-main-back.vercel.app/createbooking12",{
                                          method: "POST",
                                          headers :  {
                                              "Content-Type" : "application/json" , 
                                          } ,
                                          body : JSON.stringify({
                                            pickup ,  
                                            drop ,
                                            choosetime ,
                                            pickupdate , 
                                            dropdate,
                                            phone ,
                                            car ,
                                            email ,
                                            Product_Image_Upload : "A",
                                            // Product_Image_Upload : res2.url,
                                          })
                                      })
                                      .then(res11=>res11.json())
                                      .then((res12)=>{ 
                                          if(res12.Error){
                                            this.setState({displayNone : true})

                                          swal(res12.Error)
                                      }
                                      else{
                                              swal("Successfully Create Booking ! Wait Our Team Will Contact Soon.");
                                              this.setState({
                                                // Product_Catagories: "",
                                                // Product_Image_Upload : "",
                                                url : "",
                                          })
                                          this.setState({displayNone : true})

                                      }
                                  })
                                  .catch(err=>{
                                    swal("Successfully Create Booking ! Wait Our Team Will Contact Soon.");

                                      // swal("There is An Error")                  
                                      }) 
                                  // }
                                  // else{
                                  //   swal("Successfully Upload your Product!");

                                  //     swal("Upload Again")                  
                                  // }
              // })
              // .catch(err=>swal("There is an Error"))

          }  

    
          const setpickup11 = (e)=>{
            setpickup1(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
            if(drop1){
              let a = false
              for (let i = 0; i < CarBookPrice.length; i++) {

                if(e === CarBookPrice[i].Pickup && drop1 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla 1.6"){
                  a = true
                  if(CarBookPrice[i].Price){
                    document.getElementById("car1").innerHTML ="Rs" + CarBookPrice[i].Price
                  }
                  else{
                    document.getElementById("car1").innerHTML ="No Any Package"
                  }
                }
                
              }
              if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
            }

          }
          const setpickup21 = (e)=>{
            setpickup2(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
            if(drop2){
              let a = false

              for (let i = 0; i < CarBookPrice.length; i++) {

                if(e === CarBookPrice[i].Pickup && drop2 === CarBookPrice[i].Drop && CarBookPrice[i].Car === " Toyota Land Cruiser V8"){
                  a=  true

                  if(CarBookPrice[i].Price){
                    document.getElementById("car2").innerHTML ="Rs" + CarBookPrice[i].Price
                  }
                  else{
                    document.getElementById("car2").innerHTML ="No Any Package"
                  }
                }
                
                
              }
              if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
            }

          }
          const setpickup31 = (e)=>{
            setpickup3(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
            if(drop3){
              let a = false

              for (let i = 0; i < CarBookPrice.length; i++) {

                if(e === CarBookPrice[i].Pickup && drop3 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Prado TX"){
                  a=  true

                  if(CarBookPrice[i].Price){
                    document.getElementById("car3").innerHTML ="Rs" + CarBookPrice[i].Price
                  }
                  else{
                    document.getElementById("car3").innerHTML ="No Any Package"
                  }
                }
                
                
              }
              if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
            }

          }
          const setpickup41 = (e)=>{
            setpickup4(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
            if(drop1){
              let a = false

              for (let i = 0; i < CarBookPrice.length; i++) {

                if(e === CarBookPrice[i].Pickup && drop4 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Coaster High Roof"){
                  a=  true

                  if(CarBookPrice[i].Price){
                    document.getElementById("car4").innerHTML ="Rs" + CarBookPrice[i].Price
                  }
                  else{
                    document.getElementById("car4").innerHTML ="No Any Package"
                  }
                }
                
                
              }
              if(!a)  document.getElementById("car1").innerHTML ="No Any Package"
            }

          }
          const setdrop11 = (e)=>{
            setdrop1(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
          if(pickup1){
            let a = false
            for (let i = 0; i < CarBookPrice.length; i++) {
              if(pickup1 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla 1.6"){
                a = true  
                if(CarBookPrice[i].Price> 1){
                    console.log("gdfg",CarBookPrice[i].Price)
                    document.getElementById("car1").innerHTML ="Rs" + CarBookPrice[i].Price
                  }
                  else{
                  console.log("gdfg",CarBookPrice[i].Price)
                  document.getElementById("car1").innerHTML ="No Any Package"
                }
              }
              
              }
              if(!a)  document.getElementById("car1").innerHTML ="No Any Package"

            }
            console.log("gdfg")
          }
          const setdrop21 = (e)=>{
            setdrop2(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
            if(pickup2){
              let a = false

              for (let i = 0; i < CarBookPrice.length; i++) {
                if(pickup2 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Land Cruiser V8"){
                  a=  true

                  if(CarBookPrice[i].Price){
                  document.getElementById("car2").innerHTML ="Rs" + CarBookPrice[i].Price
                }
                else{
                  document.getElementById("car2").innerHTML ="No Any Package"
                }
              }
              
            }
            if(!a)  document.getElementById("car2").innerHTML ="No Any Package"
            }
          }
          const setdrop31 = (e)=>{
            setdrop3(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
            if(pickup3){
              let a = false

              for (let i = 0; i < CarBookPrice.length; i++) {
                if(pickup3 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Prado TX"){
                  a=  true

                  if(CarBookPrice[i].Price){
                  document.getElementById("car3").innerHTML ="Rs" + CarBookPrice[i].Price
                }
                else{
                  document.getElementById("car3").innerHTML ="No Any Package"
                }
              }
              
              }
              if(!a)  document.getElementById("car3").innerHTML ="No Any Package"

            }
          }
          const setdrop41 = (e)=>{
            setdrop4(e)
            if(couponShow)
            setcouponShow(false)
          else setcouponShow(true)
            if(pickup4){
              let a = false

              for (let i = 0; i < CarBookPrice.length; i++) {
                if(pickup4 === CarBookPrice[i].Pickup && e === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Coaster High Roof"){
                a=  true
                  if(CarBookPrice[i].Price){
                  document.getElementById("car4").innerHTML ="Rs" + CarBookPrice[i].Price
                }
                else{
                  document.getElementById("car4").innerHTML ="No Any Package"
                }
              }
              
              }
              if(!a)  document.getElementById("car4").innerHTML ="No Any Package"

            }
          }



          const setCarPick1 = (e)=>{
            if(pickup1 && drop1){
              for (let i = 0; i < CarBookPrice.length; i++) {
                if(pickup1 === CarBookPrice[i].Pickup && drop1 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Corolla 1.6"){
              
                  if(CarBookPrice[i].Price){
                    localStorage.setItem("CarBookPric", JSON.stringify({
                      Car : "Toyota Corolla", 
                      Price :CarBookPrice[i].Price, 
                      Pickup :pickup1,
                      Drop : drop1
                    }))
                    swal("Booking Added in your Cart")
                    props.history.push("/checkout2")
                }
                else{
                  swal("No Package Avaiable")
                }
               
              }
              
              }

            }
            else{
              swal("Select PickUp & Drop Off City")
            }
          }
          const setCarPick2 = (e)=>{
            if(pickup2 && drop2){
              for (let i = 0; i < CarBookPrice.length; i++) {
                if(pickup2 === CarBookPrice[i].Pickup && drop2 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Land Cruiser V8"){
              
                  if(CarBookPrice[i].Price){
                    localStorage.setItem("CarBookPric", JSON.stringify({
                      Car : "THonda BRV", 
                      Price :CarBookPrice[i].Price, 
                      Pickup :pickup2,
                      Drop : drop2
                    }))
                    swal("Booking Added in your Cart")
                    props.history.push("/checkout2")
                }
                else{
                  swal("No Package Avaiable")
                }
               
              }
              
              }

            }
            else{
              swal("Select PickUp & Drop Off City")
            }
          }
          const setCarPick3 = (e)=>{
            if(pickup3 && drop3){
              for (let i = 0; i < CarBookPrice.length; i++) {
                if(pickup3 === CarBookPrice[i].Pickup && drop3 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Prado TX"){
              
                  if(CarBookPrice[i].Price){
                    localStorage.setItem("CarBookPric", JSON.stringify({
                      Car : "Grand Cabin", 
                      Price :CarBookPrice[i].Price, 
                      Pickup :pickup3,
                      Drop : drop3
                    }))
                    swal("Booking Added in your Cart")
                    props.history.push("/checkout2")
                }
                else{
                  swal("No Package Avaiable")
                }
               
              }
              
              }

            }
            else{
              swal("Select PickUp & Drop Off City")
            }
          }
          const setCarPick4 = (e)=>{
            if(pickup4 && drop4){
              for (let i = 0; i < CarBookPrice.length; i++) {
                if(pickup4 === CarBookPrice[i].Pickup && drop4 === CarBookPrice[i].Drop && CarBookPrice[i].Car === "Toyota Coaster High Roof"){
              
                  if(CarBookPrice[i].Price){
                    localStorage.setItem("CarBookPric", JSON.stringify({
                      Car : "Saloon Coaster", 
                      Price :CarBookPrice[i].Price, 
                      Pickup :pickup4,
                      Drop : drop4
                    }))
                    swal("Booking Added in your Cart")
                    props.history.push("/checkout2")
                }
                else{
                  swal("No Package Avaiable")
                }
               
              }
              
              }

            }
            else{
              swal("Select PickUp & Drop Off City")
            }
          }

          
const addtocartproduct = (productDetail) =>{
    swal("Update!", "Your Item Add in Cart!", "success");
  
        
    // document.getElementById("myDi").style.visibility = "visible"
    // setTimeout(()=>{
    // document.getElementById("myDi").style.visibility = "hidden"
  
    // },1000)
    console.log(productDetail)
    if (productDetail[0].Product_Price_Discounted === null){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price * 1 }
    var data1 = JSON.parse(localStorage.getItem("Cart")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Cart")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Cart" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Cart" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Cart" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Product_Price_Discounted){
    const data =  {...productDetail[0] ,
        Percentage : 0,
        DeliveryStatus : "Pending",
        Pieces : 1 ,
        Total_Product_Price  : productDetail[0].Product_Price_Discounted * 1 }
    var data1 = JSON.parse(localStorage.getItem("Cart")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id ){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
    return {...item,
        Pieces : 1 + item.Pieces,
        Total_Product_Price  : (productDetail[0].Product_Price_Discounted * 1 )+ item.Total_Product_Price}
    }
    else{
    console.log("double not match");
    return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Cart")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Cart" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Cart" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Cart" , JSON.stringify([data]) )
  
    }
  
    }
    else if (productDetail[0].Size_Discounted[0]){
        
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Size_Discounted[0],
                Total_Product_Price  : productDetail[0].Size_Discounted[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Cart")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Size_Discounted[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Cart")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Cart" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Cart" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Cart" , JSON.stringify([data]) )
  
    }
  
    }
    else{
  
  
  
  
            const data =  {...productDetail[0] ,
                Percentage : 0,
                DeliveryStatus : "Pending",
                Pieces : 1 ,
                Size : productDetail[0].Size[0],
                Product_Price_Discounted : productDetail[0].Price[0],
                Total_Product_Price  : productDetail[0].Price[0] * 1 }
    var data1 = JSON.parse(localStorage.getItem("Cart")) 
    if (data1){
    var data3 = data1.map((item) => {
        if(item._id === data._id &&  item.Size === productDetail[0].Size[0]){
            console.log("double");
    ;                   localStorage.setItem("double",JSON.stringify(true))
        return {...item,
            Pieces : 1 + item.Pieces,
            Total_Product_Price  : (productDetail[0].Price[0] * 1 )+ item.Total_Product_Price}
    }
    else{
        console.log("double not match");
        return item
    }
  
    })
    var data5 =  JSON.parse(localStorage.getItem("double")) 
    console.log(DataPart2.length, data3.length,data5);
    var data10 =  JSON.parse(localStorage.getItem("Cart")) 
  
    if(data10.length=== data3.length && data5){
    console.log("double remove");
    localStorage.removeItem("double")
    localStorage.setItem("Cart" , JSON.stringify(data3) )
  
    }
    else{
    console.log("Differet");
    var data2 = [...data1 , data]
  
    localStorage.setItem("Cart" , JSON.stringify(data2) )
    }
    }
    else{
    console.log("1");
    localStorage.setItem("Cart" , JSON.stringify([data]) )
  
    }
  
  
    }
  
  
    props.history.push("/checkout")
  
  
  }
        return (
          <div>
          
  
          <div class="header-carousel">
              <div id="carouselId" class="carousel slide" data-bs-ride="carousel" data-bs-interval="false" >
                  <ol class="carousel-indicators">
                      <li data-bs-target="#carouselId" data-bs-slide-to="0" class="active" aria-current="true" aria-label="First slide"></li>
                      <li data-bs-target="#carouselId" data-bs-slide-to="1" aria-label="Second slide"></li>
                  </ol>

                  <div class="carousel-inner" role="listbox">
                  {/* <div class="carousel-inner iiioio" role="listbox"> */}
                  {/* <Carousel autoPlay={true} interval={4000} showThumbs={false} showArrows={true} showStatus={false} swipeable={true} swipeAble={true} infiniteLoop={true}> */}
                      <div class="carousel-item active">
                          <img src={sli1} class="img-fluid w-100" alt="First slide"/>
                          <div class="carousel-caption">
                              <div class="container py-4">
                                  <div class="row g-5">
                                      <div class="col-lg-6 fadeInLeft animated" data-animation="fadeInLeft" data-delay="1s" style={{animationDelay: "1s"}}>
                                          <div class="bg-secondary rounded p-5">
                                              <h4 class="text-white mb-4">CONTINUE CAR RESERVATION</h4>
                                              <form  onSubmit={(e)=>SubmitDataMessage(e)}>
                                                  <div class="row g-3">
                                                      <div class="col-12">
                                                          <select class="form-select" style={{fontSize: "1.5rem"}} aria-label="Default select example"  value={car} onChange={(e)=>setcar(e.target.value)}>
                                                              {/* 
                                                              <option value="1">VW Golf VII</option>
                                                              <option value="2">Audi A1 S-Line</option>
                                                              <option value="3">Toyota Camry</option>
                                                              <option value="4">BMW 320 ModernLine</option> */}
                                                              <option selected>Select Your Car type</option>
                                                              <option value="Saloon Coaster">Saloon Coaster</option>
                      <option value="GRAND CABIN">GRAND CABIN</option>
                      <option value="HONDA BRV">HONDA BRV</option>
                      <option value="Coaster 4c Salon">Coaster 4c Salon</option>
                      <option value="Coaster 5c Salon">Coaster 5c Salon</option>
                      <option value="Grand Cabin 224">Grand Cabin 224</option>
                      <option value="Changan karvaan 7 seater">Changan karvaan 7 seater</option>
                      <option value="Apv 7 seater">Apv 7 seater</option>
                      <option value="Honda BRV">Honda BRV</option>
                      <option value="Voxy 7 seater">Voxy 7 seater</option>
                      <option value="Land Cruiser Limousine">Land Cruiser Limousine</option>
                      <option value="Mercedes C Class">Mercedes C Class</option>
                      <option value="Audi A6">Audi A6</option>
                      <option value="Audi A5">Audi A5</option>
                      <option value="Audi A4">Audi A4</option>
                      <option value="V8 Land cruiser">V8 Land cruiser</option>
                      <option value="Toyota Revo">Toyota Revo</option>
                      <option value="Prado">Prado</option>
                      <option value="Fortuner">Fortuner</option>
                      <option value="Civic 2021">Civic 2021</option>
                      <option value="TOYOTA Grande X">TOYOTA Grande X</option>
                      <option value="Altis X Corolla 1.6">Altis X Corolla 1.6</option>
                      <option value="TOYOTA Corolla Gli">TOYOTA Corolla Gli</option>
                      <option value="TOYOTA COROLLA">TOYOTA COROLLA</option>

                                                          </select>
                                                      </div>
                                                      <div class="col-12">
                                                          <div class="input-group">
                                                              <div class="d-flex align-items-center bg-light text-body rounded-start p-2">
                                                                  <span class="fas fa-map-marker-alt"></span> <span class="ms-1">Pick Up</span>
                                                              </div>
                                                              <select class="form-select" style={{fontSize: "1.5rem"}}  required="required" aria-required="true"  value={pickup} onChange={(e)=>setpickup(e.target.value)} >
                    <option value="Enter a City or Airport">Enter a City or Airport</option>
                      <option value="Karachi">Karachi</option>
                      <option value="Lahore">Lahore</option>
                      <option value="Multan">Multan</option>
                      <option value="Islamabad">Islamabad</option>
                    </select>
                                                              {/* <input class="form-control" type="text" placeholder="Enter a City or Airport" aria-label="Enter a City or Airport"/> */}
                                                          </div>
                                                      </div>
                                                      <div class="col-12">
                                                          <a href="#" class="text-start text-white d-block mb-2">Need a different drop-off location?</a>
                                                          <div class="input-group">
                                                              <div class="d-flex align-items-center bg-light text-body rounded-start p-2">
                                                                  <span class="fas fa-map-marker-alt"></span><span class="ms-1">Drop off</span>
                                                              </div>
                                                              <select class="form-select"  style={{fontSize: "1.5rem"}} required="required" aria-required="true"  value={drop} onChange={(e)=>setdrop(e.target.value)} >
                      <option value="Enter a City or Airport">Enter a City or Airport</option>
                      <option value="Karachi">Karachi</option>
                      <option value="Lahore">Lahore</option>
                      <option value="Multan">Multan</option>
                      <option value="Islamabad">Islamabad</option>
                    </select>
                                                              {/* <input class="form-control" type="text" placeholder="Enter a City or Airport" aria-label="Enter a City or Airport"/> */}
                                                          </div>
                                                      </div>
                                                      <div class="col-12">
                                                          <div class="input-group">
                                                              <div class="d-flex align-items-center bg-light text-body rounded-start p-2">
                                                                  <span class="fas fa-calendar-alt"></span><span class="ms-1">Pick Up</span>
                                                              </div>
                                                              <input class="form-control" type="date"  style={{fontSize: "1.5rem"}} value={pickupdate} onChange={(e)=>setpickupdate(e.target.value)}/>
                                                              <input class="form-control ms-3" type="time" style={{fontSize: "1.5rem"}} value={choosetime} onChange={(e)=>setchoosetime(e.target.value)}/>
                                                              {/* <select class="form-select ms-3" aria-label="Default select example"  value={choosetime} onChange={(e)=>setchoosetime(e.target.value)}>
                                                                  <option value="12:00AM">12:00AM</option>
                                                                  <option value="1:00PM">12:00AM</option>
                                                                  <option value="12:00AM">12:00AM</option>
                                                                  <option value="12:00AM">12:00AM</option>
                                                                  <option value="12:00AM">12:00AM</option>
                                                                  <option value="12:00AM">12:00AM</option>
                                                                  <option value="12:00AM">12:00AM</option>
                                                                  <option value="12:00AM">12:00AM</option>
                                                                  <option value="12:00AM">12:00AM</option>
                                                                  <option value="12:00AM">12:00AM</option>
                                                                  <option value="12:00AM">12:00AM</option>
                                                                  <option value="12:00AM">12:00AM</option>
                                                                  <option value="12:00AM">12:00AM</option>
                                                               
                                                              </select> */}
                                                          </div>
                                                      </div>
                                                      <div class="col-12">
                                                          <div class="input-group">
                                                              <div class="d-flex align-items-center bg-light text-body rounded-start p-2">
                                                                  <span class="fas fa-calendar-alt"></span><span class="ms-1">Drop off</span>
                                                              </div>
                                                              <input class="form-control" type="date"  style={{fontSize: "1.5rem"}} value={dropdate} onChange={(e)=>setdropdate(e.target.value)}/>
                                                              <input class="form-control ms-3" type="time" style={{fontSize: "1.5rem"}}/>

                                                              {/* <select class="form-select ms-3" aria-label="Default select example">
                                                                  <option selected>12:00AM</option>
                                                                  <option value="1">1:00AM</option>
                                                                  <option value="2">2:00AM</option>
                                                                  <option value="3">3:00AM</option>
                                                                  <option value="4">4:00AM</option>
                                                                  <option value="5">5:00AM</option>
                                                                  <option value="6">6:00AM</option>
                                                                  <option value="7">7:00AM</option>
                                                              </select> */}
                                                          </div>
                                                      </div>
                                                      <div class="col-12">
                                                          <div class="input-group">
                                                              {/* <div class="d-flex align-items-center bg-light text-body rounded-start p-2">
                                                                  <span class="fas fa-calendar-alt"></span><span class="ms-1">Drop off</span>
                                                              </div> */}
                                                              <input class="form-control" type="email"  style={{fontSize: "1.5rem"}} required
                    value={email} onChange={(e)=>setemail(e.target.value)}  placeholder='Enter Your Email'/>
                                                              
                                                              <input class="form-control ms-3"  type="text" style={{fontSize: "1.5rem"}} required
                    value={phone} onChange={(e)=>setphone(e.target.value)}  placeholder='Enter Your Phone Number'/>

                                                              {/* <select class="form-select ms-3" aria-label="Default select example">
                                                                  <option selected>12:00AM</option>
                                                                  <option value="1">1:00AM</option>
                                                                  <option value="2">2:00AM</option>
                                                                  <option value="3">3:00AM</option>
                                                                  <option value="4">4:00AM</option>
                                                                  <option value="5">5:00AM</option>
                                                                  <option value="6">6:00AM</option>
                                                                  <option value="7">7:00AM</option>
                                                              </select> */}
                                                          </div>
                                                      </div>
                                                      <div class="col-12">
                                                          <button class="btn btn-light w-100 py-2"  style={{fontSize: "1.5rem"}}>Book Now</button>
                                                      </div>
                                                  </div>
                                              </form>
                                          </div>
                                      </div>
                                      <div class="col-lg-6 d-none d-lg-flex fadeInRight animated" data-animation="fadeInRight" data-delay="1s" style={{animationDelay: "1s"}}>
                                          <div class="text-start">
                                              <h1 class="display-5 text-white">Get 15% off your rental Plan your trip now</h1>
                                              <p style={{color:"white"}}>Treat yourself in USA</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      {/* <div class="carousel-item active">
                          <img src={sli2} class="img-fluid w-100" alt="First slide"/>
                          <div class="carousel-caption">
                              <div class="container py-4">
                                  <div class="row g-5">
                                      <div class="col-lg-6 fadeInLeft animated" data-animation="fadeInLeft" data-delay="1s" style={{animationDelay: "1s"}}>
                                          <div class="bg-secondary rounded p-5">
                                              <h4 class="text-white mb-4">CONTINUE CAR RESERVATION</h4>
                                              <form>
                                                  <div class="row g-3">
                                                      <div class="col-12">
                                                          <select class="form-select" aria-label="Default select example">
                                                              <option selected>Select Your Car type</option>
                                                              <option value="1">VW Golf VII</option>
                                                              <option value="2">Audi A1 S-Line</option>
                                                              <option value="3">Toyota Camry</option>
                                                              <option value="4">BMW 320 ModernLine</option>
                                                          </select>
                                                      </div>
                                                      <div class="col-12">
                                                          <div class="input-group">
                                                              <div class="d-flex align-items-center bg-light text-body rounded-start p-2">
                                                                  <span class="fas fa-map-marker-alt"></span><span class="ms-1">Pick Up</span>
                                                              </div>
                                                              <input class="form-control" type="text" placeholder="Enter a City or Airport" aria-label="Enter a City or Airport"/>
                                                          </div>
                                                      </div>
                                                      <div class="col-12">
                                                          <a href="#" class="text-start text-white d-block mb-2">Need a different drop-off location?</a>
                                                          <div class="input-group">
                                                              <div class="d-flex align-items-center bg-light text-body rounded-start p-2">
                                                                  <span class="fas fa-map-marker-alt"></span><span class="ms-1">Drop off</span>
                                                              </div>
                                                              <input class="form-control" type="text" placeholder="Enter a City or Airport" aria-label="Enter a City or Airport"/>
                                                          </div>
                                                      </div>
                                                      <div class="col-12">
                                                          <div class="input-group">
                                                              <div class="d-flex align-items-center bg-light text-body rounded-start p-2">
                                                                  <span class="fas fa-calendar-alt"></span><span class="ms-1">Pick Up</span>
                                                              </div>
                                                              <input class="form-control" type="date"/>
                                                              <select class="form-select ms-3" aria-label="Default select example">
                                                                  <option selected>12:00AM</option>
                                                                  <option value="1">1:00AM</option>
                                                                  <option value="2">2:00AM</option>
                                                                  <option value="3">3:00AM</option>
                                                                  <option value="4">4:00AM</option>
                                                                  <option value="5">5:00AM</option>
                                                                  <option value="6">6:00AM</option>
                                                                  <option value="7">7:00AM</option>
                                                              </select>
                                                          </div>
                                                      </div>
                                                      <div class="col-12">
                                                          <div class="input-group">
                                                              <div class="d-flex align-items-center bg-light text-body rounded-start p-2">
                                                                  <span class="fas fa-calendar-alt"></span><span class="ms-1">Drop off</span>
                                                              </div>
                                                              <input class="form-control" type="date"/>
                                                              <select class="form-select ms-3" aria-label="Default select example">
                                                                  <option selected>12:00AM</option>
                                                                  <option value="1">1:00AM</option>
                                                                  <option value="2">2:00AM</option>
                                                                  <option value="3">3:00AM</option>
                                                                  <option value="4">4:00AM</option>
                                                                  <option value="5">5:00AM</option>
                                                                  <option value="6">6:00AM</option>
                                                                  <option value="7">7:00AM</option>
                                                              </select>
                                                          </div>
                                                      </div>
                                                      <div class="col-12">
                                                          <button class="btn btn-light w-100 py-2">Book Now</button>
                                                      </div>
                                                  </div>
                                              </form>
                                          </div>
                                      </div>
                                      <div class="col-lg-6 d-none d-lg-flex fadeInRight animated" data-animation="fadeInRight" data-delay="1s" style={{animationDelay: "1s"}}>
                                          <div class="text-start">
                                              <h1 class="display-5 text-white">Get 15% off your rental! Choose Your Model </h1>
                                              <p>Treat yourself in USA</p>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div> */}
                  {/* </Carousel> */}
                  </div>

              </div>
          </div>
  




          <div class="container-fluid categories pb-5">
              <div class="container pb-5">
                  <div class="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                      <h1 class="display-5 text-capitalize mb-3">Find Your Best
                      <span class="text-primary"><br/> Rent a Car Ride</span></h1>
                      <p class="mb-0"  style={{fontWeight: "800"}}>Discover the Best & Affordable Rent a car Karachi with driver in Karachi,Pakistan. <br/>
                       


                      </p>
                  </div>
                  <div class="row" data-wow-delay="0.1s">
                    

{product.map((res,i)=>{
                        return(
                      <div class="col-md-6 col-lg-4 categories-item mt-1 mb-4">
                          <div class="categories-item-inner">
                              <div class="categories-img rounded-top">
                              <Link  to={"/categories/"+res.Product_Catagories+"/all-products/"+res.Product_Name+"/"+res._id}>

                                  <img src={res.Product_Image_Upload} class="img-fluid w-100 rounded-top" alt="" style={{objectFit: "scale-down",background:"white"}}/>
                             </Link>
                              </div>
                              <div class="categories-content rounded-bottom p-4"  style={{fontSize: "small"}}>
                                  <h4>{res.Product_Name}</h4>
                                  <div class="categories-review mb-4">
                                      <div class="me-3">4.{Math.floor(Math.random() * 5) + 5} Review</div>
                                      <div class="d-flex justify-content-center text-secondary">
                                          <i class="fas fa-star"></i>
                                          <i class="fas fa-star"></i>
                                          <i class="fas fa-star"></i>
                                          <i class="fas fa-star"></i>
                                          <i class="fas fa-star text-body"></i>
                                      </div>
                                  </div>
                                  {/* <div class="mb-4">
                                      <h4 class="bg-white text-primary rounded-pill py-2 px-4 mb-0">$99:00/Day</h4>
                                  </div> */}
                                  <div class="row gy-2 gx-0 text-center mb-4">
                                      <div class="col-4 border-end border-white">
                                          <i class="fa fa-users text-dark"></i> <span class="text-body ms-1">{res && res.person && res.person} Seat</span>
                                      </div>
                                      <div class="col-4 border-end border-white">
                                          <i class="fa fa-car text-dark"></i> <span class="text-body ms-1">{res && res.luggage && res.luggage} Lugg</span>
                                      </div>
                                      <div class="col-4">
                                          <i class="fa fa-gas-pump text-dark"></i> <span class="text-body ms-1">{res && res.petrol && res.petrol}</span>
                                      </div>
                                      <div class="col-4 border-end border-white">
                                          <i class="fa fa-car text-dark"></i> <span class="text-body ms-1">{res && res.doors && res.doors} doors</span>
                                      </div>
                                      <div class="col-4 border-end border-white">
                                          <i class="fa fa-cogs text-dark"></i> <span class="text-body ms-1">{res && res.cc && res.cc}</span>
                                      </div>
                                      <div class="col-4">
                                          <i class="fa fa-road text-dark"></i> <span class="text-body ms-1">{res && res.ac && res.ac === "yes" ? "Ac" : "No AC"}</span>
                                      </div>
                                  </div>
                               


                                <div class="row">
                                    <div class="col-3">
                                        {/* <a class="btn btn-primary rounded-pill d-flex justify-content-center py-3">Call Now</a> */}
                                        <a href="tel:+923262021001" className="shadow-none" ><img src={"https://uxwing.com/wp-content/themes/uxwing/download/communication-chat-call/accept-call-icon.png"} alt="" style={{width:"40px",height:"40px",marginTop: "-5px"}} /></a>
                                    </div>
                                    <div class="col-6">
                                        <a onClick={()=>addtocartproduct([res])} className="shadow-none" style={{borderRadius:"10px",minWidth:"300px",maxWidth:"350px",color:"white",background :"#ea001e",padding :"9px 13px",color  :"white",fontSize:"17px",fontWeight:"bold"}} >Book Now</a>
                                    </div>
                                    <div class="col-3">
                                        <a href={"https://wa.me/923262021001?text=Hi *Car Rental Pk *! I need more info about this"+res.productName} className="shadow-none" ><img src={"https://static.vecteezy.com/system/resources/previews/016/716/480/non_2x/whatsapp-icon-free-png.png"} alt="" style={{width:"40px",height:"40px",marginTop: "-5px"}} /></a>
                                        {/* <a  class="btn btn-primary rounded-pill d-flex justify-content-center py-3">Book Now</a> */}
                                    </div>
                                </div>



                            

                              </div>
                          </div>
                      </div>
                        )
                    })
                }
                    
                      
               
                  </div>
              </div>
          </div>









          <div class="container-fluid overflow-hidden about py-5">
              <div class="container py-5">
                  <div class="row g-5">
                      <div class="col-xl-6 wow fadeInLeft" data-wow-delay="0.2s">
                          <div class="about-item">
                              <div class="pb-5">
                                  <h1 class="display-5 text-capitalize">Car Rental <span class="text-primary">About</span></h1>
                                  <p class="mb-0">Welcome to Car Rental Pk , your premier destination for reliable and affordable car rental services in Karachi. With a wide selection of vehicles, competitive rates, and flexible rental options, we strive to meet all your transportation needs.
                                  Book with us today and discover the convenience and quality that set us apart.
                                  </p>
                              </div>
                              <div class="row g-4">
                                  <div class="col-lg-6">
                                      <div class="about-item-inner border p-4">
                                          <div class="about-icon mb-4">
                                              <img src={ab1} class="img-fluid w-50 h-50" alt="Icon"/>
                                          </div>
                                          <h5 class="mb-3">Our Vision</h5>
                                          <p class="mb-0">To become Karachi's leading car rental provider by delivering superior service, and innovative transportation solutions.

</p>
                                      </div>
                                  </div>
                                  <div class="col-lg-6">
                                      <div class="about-item-inner border p-4">
                                          <div class="about-icon mb-4">
                                              <img src={ab2} class="img-fluid h-50 w-50" alt="Icon"/>
                                          </div>
                                          <h5 class="mb-3">Our Mision</h5>
                                          <p class="mb-0">To offer a  enjoyable car rental experience with a diverse fleet and 24/7 support, ensuring customer satisfaction and convenience.






</p>
                                      </div>
                                  </div>
                              </div>
                              <p class="text-item my-4">Whether you're traveling for business, leisure, or special occasions, Car Rental Pk  is here to make your journey smooth and enjoyable.
                              </p>
                              <div class="row g-4">
                                  <div class="col-lg-6">
                                      <div class="text-center rounded bg-secondary p-4">
                                          <h1 class="display-6 text-white">17</h1>
                                          <h5 class="text-light mb-0">Years Of Experience</h5>
                                      </div>
                                  </div>
                                  <div class="col-lg-6">
                                      <div class="rounded">
                                          <p class="mb-2"><i class="fa fa-check-circle text-primary me-1"></i> Wide Range of Vehicles: </p>
                                          <p class="mb-2"><i class="fa fa-check-circle text-primary me-1"></i> Affordable Rates:</p>
                                          <p class="mb-2"><i class="fa fa-check-circle text-primary me-1"></i> 24/7 Customer Support: </p>
                                          <p class="mb-0"><i class="fa fa-check-circle text-primary me-1"></i> Flexible Rental Options:</p>
                                      </div>
                                  </div>
                                  <div class="col-lg-5 d-flex align-items-center">
                                      <a href="#" class="btn btn-primary rounded py-3 px-5">More About Us</a>
                                  </div>
                                  {/* <div class="col-lg-7">
                                      <div class="d-flex align-items-center">
                                          <img src="img/attachment-img.jpg" class="img-fluid rounded-circle border border-4 border-secondary" style={{width: '100px', height: "100px"}} alt="Image"/>
                                          <div class="ms-4">
                                              <h4>William Burgess</h4>
                                              <p class="mb-0">Carveo Founder</p>
                                          </div>
                                      </div>
                                  </div> */}
                              </div>
                          </div>
                      </div>
                      <div class="col-xl-6 wow fadeInRight" data-wow-delay="0.2s">
                          <div class="about-img">
                              <div class="img-1">
                                  <img src={ab3} class="img-fluid rounded h-100 w-100" alt=""/>
                              </div>
                              <div class="img-2">
                                  <img src={ab4} class="img-fluid rounded w-100" alt=""/>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
  


          {/* <div class="container" >
<div class="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                      <h1 class="display-5 text-capitalize mb-3">Browse All 
                      <br/> <span class="text-primary">Destinations</span></h1>
                      <p class="mb-0">Discover the best car rental services with driver in all over Pakistan.








                      </p>
                  </div>
 <div class="executive-leadership-team change-mobile-veiw1">
		<div>
			<img src={s1} alt="Matthew McEvoy" />
			<div class="text1">
				<h2>Karachi</h2>
			</div>
		</div>
		<div>
			<img src={s2} alt="Tim Davison" />
			<div class="text1">
				<h2>Lahore</h2>
			</div>
		</div>
		<div>
			<img src={s3} alt="Maggie Wright" />
			<div class="text1">
				<h2>Islamabad
                </h2>
			</div>
		</div>
		<div>
			<img src={s4} alt="Stephanie Evans" />
			<div class="text1">
				<h2>Multan</h2>
			</div>
		</div>
	
	</div>
	</div> */}







          <div class="container-fluid service py-5">
              <div class="container py-5">
                  <div class="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                      <h1 class="display-5 text-capitalize mb-3">Car Rental <span class="text-primary">Services</span></h1>
                      <p class="mb-0">your premier destination for reliable and affordable car rental services in Karachi. With a wide selection of vehicles, competitive rates, and flexible rental options, we strive to meet all your transportation needs.
                      </p>
                  </div>
                  <div class="row g-4">
                      <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                          <div class="service-item p-4">
                              <div class="service-icon mb-4">
                                  <i class="fa fa-phone-alt fa-2x"></i>
                              </div>
                              <h5 class="mb-3">Phone Reservation</h5>
                              <p class="mb-0">Easily book your rental car with our convenient phone reservation service, available to assist you with your travel needs.

</p>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                          <div class="service-item p-4">
                              <div class="service-icon mb-4">
                                  <i class="fa fa-money-bill-alt fa-2x"></i>
                              </div>
                              <h5 class="mb-3">Special Rates</h5>
                              <p class="mb-0">Enjoy our special rates designed to provide you with affordable car rental options without compromising on quality.

</p>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                          <div class="service-item p-4">
                              <div class="service-icon mb-4">
                                  <i class="fa fa-road fa-2x"></i>
                              </div>
                              <h5 class="mb-3">One Way Rental</h5>
                              <p class="mb-0">Take advantage of our one way rental service, allowing you to pick up and drop off your rental car at different locations.

</p>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                          <div class="service-item p-4">
                              <div class="service-icon mb-4">
                                  <i class="fa fa-umbrella fa-2x"></i>
                              </div>
                              <h5 class="mb-3">24/7 Avaiable</h5>
                              <p class="mb-0">Our car rental services are available 24/7, ensuring that you have access to reliable transportation whenever you need it.

</p>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                          <div class="service-item p-4">
                              <div class="service-icon mb-4">
                                  <i class="fa fa-building fa-2x"></i>
                              </div>
                              <h5 class="mb-3">City to City</h5>
                              <p class="mb-0">Travel seamlessly from city to city with our reliable and comfortable car rental options, perfect for intercity journeys.

</p>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                          <div class="service-item p-4">
                              <div class="service-icon mb-4">
                                  <i class="fa fa-car-alt fa-2x"></i>
                              </div>
                              <h5 class="mb-3">Bullet Proof
                              </h5>
                              <p class="mb-0">Ensure your safety and peace of mind with our bulletproof car rental options, providing top-level protection for your travels.






</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
  
















          <div class="container-fluid feature py-5">
              <div class="container py-5">
                  <div class="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                      <h1 class="display-5 text-capitalize mb-3">Car Rental <br/> Karachi <span class="text-primary">Features</span></h1>
                      <p class="mb-0">Experience the best in car rental with our extensive fleet, competitive pricing, flexible rental plans, and exceptional 24/7 customer service in Karachi.







                      </p>
                  </div>
                  <div class="row g-4 align-items-center">
                      <div class="col-xl-4">
                          <div class="row gy-4 gx-0">
                              <div class="col-12 wow fadeInUp" data-wow-delay="0.1s">
                                  <div class="feature-item">
                                      <div class="feature-icon">
                                          <span class="fa fa-trophy fa-2x"></span>
                                      </div>
                                      <div class="ms-4">
                                          <h5 class="mb-3">First Class Services</h5>
                                          <p class="mb-0">Experience unmatched luxury and comfort with our First Class services, featuring gourmet meals, spacious seating, and personalized attention. Travel in style with an experience that elevates every journey.

</p>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-12 wow fadeInUp" data-wow-delay="0.3s">
                                  <div class="feature-item">
                                      <div class="feature-icon">
                                          <span class="fa fa-road fa-2x"></span>
                                      </div>
                                      <div class="ms-4">
                                          <h5 class="mb-3">24/7 Road Assistance</h5>
                                          <p class="mb-0">Enjoy peace of mind with our 24/7 Road Assistance, providing swift and reliable support for any roadside emergency. Travel confidently, knowing help is always just a call away.

</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12 col-xl-4 wow fadeInUp" data-wow-delay="0.2s">
                          <img src={sli3} class="img-fluid w-100" style={{objectFit: "cover"}} alt="Img"/>
                          {/* <img src="img/features-img.png" class="img-fluid w-100" style={{objectFit: "cover"}} alt="Img"/> */}
                      </div>
                      <div class="col-xl-4">
                          <div class="row gy-4 gx-0">
                              <div class="col-12 wow fadeInUp" data-wow-delay="0.1s">
                                  <div class="feature-item justify-content-end">
                                      <div class="text-end me-4">
                                          <h5 class="mb-3">Quality at Minimum</h5>
                                          <p class="mb-0">Get the best value for your money with our commitment to quality at minimum prices. Enjoy top-notch services and amenities without breaking the bank, ensuring a satisfying and affordable experience.
</p>
                                      </div>
                                      <div class="feature-icon">
                                          <span class="fa fa-tag fa-2x"></span>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-12 wow fadeInUp" data-wow-delay="0.3s">
                                  <div class="feature-item justify-content-end">
                                      <div class="text-end me-4">
                                          <h5 class="mb-3">Anytime Pick-Up & Drop-Off</h5>
                                          <p class="mb-0">Take advantage of our flexible anytime pick-up and drop-off services, designed to fit your schedule. Whether it's early morning or late night, we've got you covered for a seamless travel experience.






</p>
                                      </div>
                                      <div class="feature-icon">
                                          <span class="fa fa-map-pin fa-2x"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
  
        
          <div class="container-fluid counter bg-secondary py-5">
              <div class="container py-5">
                  <div class="row g-5">
                      <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
                          <div class="counter-item text-center">
                              <div class="counter-item-icon mx-auto">
                                  <i class="fas fa-thumbs-up fa-2x"></i>
                              </div>
                              <div class="counter-counting my-3">
                                  <span class="text-white fs-2 fw-bold" data-toggle="counter-up">1829</span>
                                  <span class="h1 fw-bold text-white">+</span>
                              </div>
                              <h4 class="text-white mb-0">Happy Clients</h4>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
                          <div class="counter-item text-center">
                              <div class="counter-item-icon mx-auto">
                                  <i class="fas fa-car-alt fa-2x"></i>
                              </div>
                              <div class="counter-counting my-3">
                                  <span class="text-white fs-2 fw-bold" data-toggle="counter-up">56</span>
                                  <span class="h1 fw-bold text-white">+</span>
                              </div>
                              <h4 class="text-white mb-0">Number of Cars</h4>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.5s">
                          <div class="counter-item text-center">
                              <div class="counter-item-icon mx-auto">
                                  <i class="fas fa-clock fa-2x"></i>
                              </div>
                              <div class="counter-counting my-3">
                                  <span class="text-white fs-2 fw-bold" data-toggle="counter-up">24/7</span>
                                  <span class="h1 fw-bold text-white">+</span>
                              </div>
                              <h4 class="text-white mb-0">Avaiable</h4>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.7s">
                          <div class="counter-item text-center">
                              <div class="counter-item-icon mx-auto">
                                  <i class="fas fa-building fa-2x"></i>
                              </div>
                              <div class="counter-counting my-3">
                                  <span class="text-white fs-2 fw-bold" data-toggle="counter-up">589</span>
                                  <span class="h1 fw-bold text-white">+</span>
                              </div>
                              <h4 class="text-white mb-0">Total kilometers</h4>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
  
          <div class="container-fluid feature py-5">
              <div class="container py-5">
                  <div class="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                      <h1 class="display-5 text-capitalize mb-3">Car Rental <br/> Karachi <span class="text-primary">Service Areas</span></h1>
                      <p class="mb-0">Experience the best in car rental with our extensive fleet, competitive pricing, flexible rental plans, and exceptional 24/7 customer service in Karachi.







                      </p>
                  </div>
                  <div className='row g-5'>
                    <div className="col-12 col-lg-3 col-sm-12" style={{margin  :"auto"}}>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Karachi-Pakistan">Rent A Car in Karachi Pakistan</Link></p>
                                              {/* <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Defence">Rent A Car in Defence</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Sindhi-Muslim-Society">Rent A Car in Sindhi Muslim Society</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Malir-Cantt">Rent A Car in Malir Cantt</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Korangi-Industrial-Area">Rent A Car in Korangi Industrial Area</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Bahria-Town-Karachi">Rent A Car in Bahria Town Karachi</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Clifton">Rent A Car in Clifton</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Port-Qasim">Rent A Car in Port Qasim</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Nizamabad">Rent A Car in Nizamabad</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Bufferzone">Rent A Car in Bufferzone</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Tariq-Road">Rent A Car in Tariq Road</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Baldia-Town">Rent A Car in Baldia Town</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-North-Nizamabad">Rent A Car in North-Nizamabad</Link></p> */}
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Gulistan-e-Johar">Rent A Car in Gulistan-e-Johar</Link></p>
                                              {/* <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Gulshan-e-Iqbal">Rent A Car in Gulshan-e-Iqbal</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Gulshan-e-Hadeed">Rent A Car in Gulshan-e-Hadeed</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Gulshan-e-Maymar">Rent A Car in Gulshan-e-Maymar</Link></p> */}
                                               <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-karachi">Rent A Car in karachi</Link></p>
                                               {/* <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/car-rental-in-karachi-pakistan">Car Rental in karachi Pakistan</Link></p> */}
                                               <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Lahore">Rent A Car in Lahore</Link></p>
                          
              
            </div>
            <div className="col-12 col-lg-3 col-sm-12" style={{margin  :"auto"}}>
                                           
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Pakistan">Rent A Car in Pakistan</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Islamabad">Rent A Car in Islamabad</Link></p>
                                              {/* <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Jhelum">Rent A Car in Jhelum</Link></p> */}
                                             
                                              {/* <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Faisalabad">Rent A Car in Faisalabad</Link></p> */}
                                              {/* <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Larkana">Rent A Car in Larkana</Link></p> */}
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Hyderabad">Rent A Car in Hyderabad</Link></p>
                                              {/* <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Abbottabad">Rent A Car in Abbottabad</Link></p> */}
                                              {/* <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Gujranwala">Rent A Car in Gujranwala</Link></p> */}
                                              {/* <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Rahim-Yaar-Khan">Rent A Car in Rahim Yaar Khan</Link></p> */}
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Peshawar">Rent A Car in Peshawar</Link></p>
                                              {/* <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Sukkur">Rent A Car in Sukkur</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Dadu">Rent A Car in Dadu</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Shahdadkot">Rent A Car in Shahdadkot</Link></p> */}

            </div>
            <div className="col-12 col-lg-3 col-sm-12" style={{margin  :"auto"}}>
                                            
            <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Multan">Rent A Car in Multan</Link></p>
            <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-jhelum">Rent a Car Jhelum</Link></p>

                                              {/* <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Sahiwal">Rent A Car in Sahiwal</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Sialkot">Rent A Car in Sialkot</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Gujrat">Rent A Car in Gujrat</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Nawabshah">Rent A Car in Nawabshah</Link></p> */}
                                              {/* <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/categories/One%20Way%20Drop/all-products">One Way Drop</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/one-way-drop-in-karachi-to-islamabad">One Way Drop karachi to Islamabad</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/one-way-drop-in-karachi-to-faisalabad">One Way Drop karachi to faisalabad</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/one-way-drop-in-karachi-to-multan">One Way Drop karachi to Multan</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/one-way-drop-in-karachi-to-lahore">One Way Drop karachi to lahore</Link></p> */}
                                              {/* <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-airport-pick-up &-drop-off">Rent A Car For Airport Pick & Drop</Link></p> */}
                                              {/* <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Airport-Pick-&-Drop">Rent A Car For Airport Pick & Drop</Link></p> */}
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-Wedding-Rent-A-Car">Rent A Car in Wedding Transport</Link></p>
                                              {/* <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/rent-a-car-in-Landhi-Industrial-Area">Rent A Car in Landhi Industrial Area</Link></p>
                                              <p style={{fontWeight:"bold",fontSize:"16px"}}><Link to="/tourism">Rent A Car For Tourism</Link></p> */}

            </div> 
                  </div>
           
            </div>
            </div>
        
  
          <div class="container-fluid steps py-5">
              <div class="container py-5">
                  <div class="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                      <h1 class="display-5 text-capitalize text-white mb-3">Rental<span class="text-primary"> Process</span></h1>
                      <p class="mb-0 text-white">The rental process involves booking, documentation, vehicle pickup, use according to terms, and timely return, ensuring a seamless experience with clear billing and customer feedback for continual service improvement.







                      </p>
                  </div>
                  <div class="row g-4">
                      <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                          <div class="steps-item p-4 mb-4">
                              <h4>Come In Contact</h4>
                              <p class="mb-0">Connect with us easily to start your rental journey.

</p>
                              <div class="setps-number">01.</div>
                          </div>
                      </div>
                      <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                          <div class="steps-item p-4 mb-4">
                              <h4>Choose A Car</h4>
                              <p class="mb-0">Select from our diverse range of vehicles tailored to your needs.

</p>
                              <div class="setps-number">02.</div>
                          </div>
                      </div>
                      <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                          <div class="steps-item p-4 mb-4">
                              <h4>Enjoy Driving</h4>
                              <p class="mb-0">Embark on your journey with comfort and confidence, experiencing the joy of the road ahead.






</p>
                              <div class="setps-number">03.</div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
  
          {/* <div class="container-fluid blog py-5">
              <div class="container py-5">
                  <div class="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                      <h1 class="display-5 text-capitalize mb-3">Rental<span class="text-primary"> Blog & News</span></h1>
                      <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ut amet nemo expedita asperiores commodi accusantium at cum harum, excepturi, quia tempora cupiditate! Adipisci facilis modi quisquam quia distinctio,
                      </p>
                  </div>
                  <div class="row g-4">
                      <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                          <div class="blog-item">
                              <div class="blog-img">
                                  <img src="img/blog-1.jpg" class="img-fluid rounded-top w-100" alt="Image"/>
                              </div>
                              <div class="blog-content rounded-bottom p-4">
                                  <div class="blog-date">30 Dec 2025</div>
                                  <div class="blog-comment my-3">
                                      <div class="small"><span class="fa fa-user text-primary"></span><span class="ms-2">Martin.C</span></div>
                                      <div class="small"><span class="fa fa-comment-alt text-primary"></span><span class="ms-2">6 Comments</span></div>
                                  </div>
                                  <a href="#" class="h4 d-block mb-3">Rental Cars how to check driving fines?</a>
                                  <p class="mb-3">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eius libero soluta impedit eligendi? Quibusdam, laudantium.</p>
                                  <a href="#" class="">Read More  <i class="fa fa-arrow-right"></i></a>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                          <div class="blog-item">
                              <div class="blog-img">
                                  <img src="img/blog-2.jpg" class="img-fluid rounded-top w-100" alt="Image"/>
                              </div>
                              <div class="blog-content rounded-bottom p-4">
                                  <div class="blog-date">25 Dec 2025</div>
                                  <div class="blog-comment my-3">
                                      <div class="small"><span class="fa fa-user text-primary"></span><span class="ms-2">Martin.C</span></div>
                                      <div class="small"><span class="fa fa-comment-alt text-primary"></span><span class="ms-2">6 Comments</span></div>
                                  </div>
                                  <a href="#" class="h4 d-block mb-3">Rental cost of sport and other cars</a>
                                  <p class="mb-3">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eius libero soluta impedit eligendi? Quibusdam, laudantium.</p>
                                  <a href="#" class="">Read More  <i class="fa fa-arrow-right"></i></a>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                          <div class="blog-item">
                              <div class="blog-img">
                                  <img src="img/blog-3.jpg" class="img-fluid rounded-top w-100" alt="Image"/>
                              </div>
                              <div class="blog-content rounded-bottom p-4">
                                  <div class="blog-date">27 Dec 2025</div>
                                  <div class="blog-comment my-3">
                                      <div class="small"><span class="fa fa-user text-primary"></span><span class="ms-2">Martin.C</span></div>
                                      <div class="small"><span class="fa fa-comment-alt text-primary"></span><span class="ms-2">6 Comments</span></div>
                                  </div>
                                  <a href="#" class="h4 d-block mb-3">Document required for car rental</a>
                                  <p class="mb-3">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eius libero soluta impedit eligendi? Quibusdam, laudantium.</p>
                                  <a href="#" class="">Read More  <i class="fa fa-arrow-right"></i></a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div> */}
  
          <div class="container-fluid banner pb-5 wow zoomInDown" data-wow-delay="0.1s">
              <div class="container pb-5">
                  <div class="banner-item rounded">
                      <img src={ban1} class="img-fluid rounded w-100" alt=""/>
                      <div class="banner-content">
                          <h2 class="text-primary">Rent Your Car</h2>
                          <h1 class="text-white">Interested in Renting?</h1>
                          <p class="text-white">Don't hesitate and send us a message.</p>
                          <div class="banner-btn">
                              <a href="https://wa.me/923262021001?text=Hi *Car Rental Pk *! I need more info about Car Rental Pk  https://rentalcarspk.site" class="btn btn-secondary rounded-pill py-3 px-4 px-md-5 me-2" style={{color:"black"}}>WhatshApp</a>
                              <a href="+923262021001" class="btn btn-primary rounded-pill py-3 px-4 px-md-5 ms-2">Contact Us</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
  
          {/* <div class="container-fluid team pb-5">
              <div class="container pb-5">
                  <div class="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                      <h1 class="display-5 text-capitalize mb-3">Customer<span class="text-primary"> Suport</span> Center</h1>
                      <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ut amet nemo expedita asperiores commodi accusantium at cum harum, excepturi, quia tempora cupiditate! Adipisci facilis modi quisquam quia distinctio,
                      </p>
                  </div>
                  <div class="row g-4">
                      <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
                          <div class="team-item p-4 pt-0">
                              <div class="team-img">
                                  <img src="img/team-1.jpg" class="img-fluid rounded w-100" alt="Image"/>
                              </div>
                              <div class="team-content pt-4">
                                  <h4>MARTIN DOE</h4>
                                  <p>Profession</p>
                                  <div class="team-icon d-flex justify-content-center">
                                      <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                                      <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-twitter"></i></a>
                                      <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-instagram"></i></a>
                                      <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-linkedin-in"></i></a>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
                          <div class="team-item p-4 pt-0">
                              <div class="team-img">
                                  <img src="img/team-2.jpg" class="img-fluid rounded w-100" alt="Image"/>
                              </div>
                              <div class="team-content pt-4">
                                  <h4>MARTIN DOE</h4>
                                  <p>Profession</p>
                                  <div class="team-icon d-flex justify-content-center">
                                      <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                                      <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-twitter"></i></a>
                                      <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-instagram"></i></a>
                                      <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-linkedin-in"></i></a>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.5s">
                          <div class="team-item p-4 pt-0">
                              <div class="team-img">
                                  <img src="img/team-3.jpg" class="img-fluid rounded w-100" alt="Image"/>
                              </div>
                              <div class="team-content pt-4">
                                  <h4>MARTIN DOE</h4>
                                  <p>Profession</p>
                                  <div class="team-icon d-flex justify-content-center">
                                      <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                                      <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-twitter"></i></a>
                                      <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-instagram"></i></a>
                                      <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-linkedin-in"></i></a>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.7s">
                          <div class="team-item p-4 pt-0">
                              <div class="team-img">
                                  <img src="img/team-4.jpg" class="img-fluid rounded w-100" alt="Image"/>
                              </div>
                              <div class="team-content pt-4">
                                  <h4>MARTIN DOE</h4>
                                  <p>Profession</p>
                                  <div class="team-icon d-flex justify-content-center">
                                      <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                                      <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-twitter"></i></a>
                                      <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-instagram"></i></a>
                                      <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-linkedin-in"></i></a>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div> */}
  
          <div class="container-fluid testimonial pb-5">
              <div class="container pb-5">
                  <div class="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                      <h1 class="display-5 text-capitalize mb-3">Our Clients<span class="text-primary"> Riviews</span></h1>
                      <p class="mb-0">Here's what our clients have to say about their experiences with us:







                      </p>
                  </div>
                  {/* <div class="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.1s"> */}
                  <div class="row">
                  <Carousel
  swipeable={false}
  draggable={true}
  showDots={false}
  arrows={true}
  responsive={responsive}
  infinite={true}
  minimumTouchDrag={2}
  autoPlay = {true}
  transitionDuration={2000}
//   autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={2500}
  keyBoardControl={true}
  customTransition="all 2s ease-out"
  containerClass="carousel-container"
//   removeArrowOnDeviceType={["tablet", "mobile"]}
//   deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>
 
<div class="col-12-md col-lg-12 testimonial-item"> <div class="testimonial-quote"><i class="fa fa-quote-right fa-2x"></i> </div> <div class="testimonial-inner p-4"> <img src={car2} class="img-fluid" alt=""/> <div class="ms-4"> <h4>John Doe</h4> <p>Marketing Manager</p> <div class="d-flex text-primary"> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star-half-alt"></i> </div> </div> </div> <div class="border-top rounded-bottom p-4"> <p class="mb-0">I was hesitant to rent a car at first, but the process was so easy and the customer service was excellent. The car was in great condition and I would definitely rent from them again.</p> </div> </div>


<div class="col-12-md col-lg-12 testimonial-item"> <div class="testimonial-quote"><i class="fa fa-quote-right fa-2x"></i> </div> <div class="testimonial-inner p-4"> <img src={car2} class="img-fluid" alt=""/> <div class="ms-4"> <h4>Jane Smith</h4> <p>Business Man</p> <div class="d-flex text-primary"> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> </div> </div> </div> <div class="border-top rounded-bottom p-4"> <p class="mb-0">I had a great experience renting a car from this company. The car was clean and well-maintained, and the staff was friendly and helpful. I would definitely recommend them to anyone in need of a rental car.</p> </div> </div>


<div class="col-12-md col-lg-12 testimonial-item"> <div class="testimonial-quote"><i class="fa fa-quote-right fa-2x"></i> </div> <div class="testimonial-inner p-4"> <img src={car2} class="img-fluid" alt=""/> <div class="ms-4"> <h4>Michael Johnson</h4> <p>Small Business</p> <div class="d-flex text-primary"> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star-half-alt"></i> <i class="fas fa-star text-body"></i> </div> </div> </div> <div class="border-top rounded-bottom p-4"> <p class="mb-0">The rental process was quick and easy, and the car was in excellent condition. The staff was friendly and helpful, and I would definitely rent from them again.</p> </div> </div>
                      </Carousel>

                  </div>
              </div>
          </div>
  
          
  
          <a href="#" class="btn btn-secondary btn-lg-square rounded-circle back-to-top"><i class="fa fa-arrow-up"></i></a>   
  
          
        </div>
        )
    
}

export default Home;