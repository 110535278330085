import React ,{useEffect , useState} from 'react'
import {Link} from "react-router-dom"
// import OwlCarousel from 'react-owl-carousel';  
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";


import car2 from './images/x1.jpg';



const Home = ( props ) => {
   
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 2,
          slidesToSlide: 2 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };
    useEffect(() =>{

        window.scrollTo(0, 0)
      
    
 },[])

 
   
        return (
          <div>
          
  
          <div class="container-fluid bg-breadcrumb">
            <div class="container text-center py-5" style={{maxWidth: "900px"}}>
                <h4 class="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">Our Testimonials</h4>
                <ol class="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style={{background: "transparent"}}>
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item"><a href="#">Pages</a></li>
                    <li class="breadcrumb-item active text-primary">Testimonials</li>
                </ol>    
            </div>
        </div>
        
          <div class="container-fluid testimonial pb-5">
              <div class="container pb-5">
                  <div class="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                      <h1 class="display-5 text-capitalize mb-3">Our Clients<span class="text-primary"> Riviews</span></h1>
                      <p class="mb-0">Here's what our clients have to say about their experiences with us:







                      </p>
                  </div>
                  {/* <div class="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.1s"> */}
                  <div class="row">
                  <Carousel
  swipeable={false}
  draggable={true}
  showDots={false}
  arrows={true}
  responsive={responsive}
  infinite={true}
  minimumTouchDrag={2}
  autoPlay = {true}
  transitionDuration={2000}
//   autoPlay={this.props.deviceType !== "mobile" ? true : false}
  autoPlaySpeed={2500}
  keyBoardControl={true}
  customTransition="all 2s ease-out"
  containerClass="carousel-container"
//   removeArrowOnDeviceType={["tablet", "mobile"]}
//   deviceType={this.props.deviceType}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>
 
<div class="col-12-md col-lg-12 testimonial-item"> <div class="testimonial-quote"><i class="fa fa-quote-right fa-2x"></i> </div> <div class="testimonial-inner p-4"> <img src={car2} class="img-fluid" alt=""/> <div class="ms-4"> <h4>John Doe</h4> <p>Marketing Manager</p> <div class="d-flex text-primary"> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star-half-alt"></i> </div> </div> </div> <div class="border-top rounded-bottom p-4"> <p class="mb-0">I was hesitant to rent a car at first, but the process was so easy and the customer service was excellent. The car was in great condition and I would definitely rent from them again.</p> </div> </div>


<div class="col-12-md col-lg-12 testimonial-item"> <div class="testimonial-quote"><i class="fa fa-quote-right fa-2x"></i> </div> <div class="testimonial-inner p-4"> <img src={car2} class="img-fluid" alt=""/> <div class="ms-4"> <h4>Jane Smith</h4> <p>Business Man</p> <div class="d-flex text-primary"> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> </div> </div> </div> <div class="border-top rounded-bottom p-4"> <p class="mb-0">I had a great experience renting a car from this company. The car was clean and well-maintained, and the staff was friendly and helpful. I would definitely recommend them to anyone in need of a rental car.</p> </div> </div>


<div class="col-12-md col-lg-12 testimonial-item"> <div class="testimonial-quote"><i class="fa fa-quote-right fa-2x"></i> </div> <div class="testimonial-inner p-4"> <img src={car2} class="img-fluid" alt=""/> <div class="ms-4"> <h4>Michael Johnson</h4> <p>Small Business</p> <div class="d-flex text-primary"> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star"></i> <i class="fas fa-star-half-alt"></i> <i class="fas fa-star text-body"></i> </div> </div> </div> <div class="border-top rounded-bottom p-4"> <p class="mb-0">The rental process was quick and easy, and the car was in excellent condition. The staff was friendly and helpful, and I would definitely rent from them again.</p> </div> </div>
                      </Carousel>

                  </div>
              </div>
          </div>
  

          <div class="container-fluid service py-5">
              <div class="container py-5">
                  <div class="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                      <h1 class="display-5 text-capitalize mb-3">Car Rental <span class="text-primary">Services</span></h1>
                      <p class="mb-0">your premier destination for reliable and affordable car rental services in Karachi. With a wide selection of vehicles, competitive rates, and flexible rental options, we strive to meet all your transportation needs.
                      </p>
                  </div>
                  <div class="row g-4">
                      <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                          <div class="service-item p-4">
                              <div class="service-icon mb-4">
                                  <i class="fa fa-phone-alt fa-2x"></i>
                              </div>
                              <h5 class="mb-3">Phone Reservation</h5>
                              <p class="mb-0">Easily book your rental car with our convenient phone reservation service, available to assist you with your travel needs.

</p>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                          <div class="service-item p-4">
                              <div class="service-icon mb-4">
                                  <i class="fa fa-money-bill-alt fa-2x"></i>
                              </div>
                              <h5 class="mb-3">Special Rates</h5>
                              <p class="mb-0">Enjoy our special rates designed to provide you with affordable car rental options without compromising on quality.

</p>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                          <div class="service-item p-4">
                              <div class="service-icon mb-4">
                                  <i class="fa fa-road fa-2x"></i>
                              </div>
                              <h5 class="mb-3">One Way Rental</h5>
                              <p class="mb-0">Take advantage of our one way rental service, allowing you to pick up and drop off your rental car at different locations.

</p>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                          <div class="service-item p-4">
                              <div class="service-icon mb-4">
                                  <i class="fa fa-umbrella fa-2x"></i>
                              </div>
                              <h5 class="mb-3">24/7 Avaiable</h5>
                              <p class="mb-0">Our car rental services are available 24/7, ensuring that you have access to reliable transportation whenever you need it.

</p>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                          <div class="service-item p-4">
                              <div class="service-icon mb-4">
                                  <i class="fa fa-building fa-2x"></i>
                              </div>
                              <h5 class="mb-3">City to City</h5>
                              <p class="mb-0">Travel seamlessly from city to city with our reliable and comfortable car rental options, perfect for intercity journeys.

</p>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                          <div class="service-item p-4">
                              <div class="service-icon mb-4">
                                  <i class="fa fa-car-alt fa-2x"></i>
                              </div>
                              <h5 class="mb-3">Bullet Proof
                              </h5>
                              <p class="mb-0">Ensure your safety and peace of mind with our bulletproof car rental options, providing top-level protection for your travels.






</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
  

          <div class="container-fluid steps py-5">
              <div class="container py-5">
                  <div class="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                      <h1 class="display-5 text-capitalize text-white mb-3">Rental<span class="text-primary"> Process</span></h1>
                      <p class="mb-0 text-white">The rental process involves booking, documentation, vehicle pickup, use according to terms, and timely return, ensuring a seamless experience with clear billing and customer feedback for continual service improvement.







                      </p>
                  </div>
                  <div class="row g-4">
                      <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                          <div class="steps-item p-4 mb-4">
                              <h4>Come In Contact</h4>
                              <p class="mb-0">Connect with us easily to start your rental journey.

</p>
                              <div class="setps-number">01.</div>
                          </div>
                      </div>
                      <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                          <div class="steps-item p-4 mb-4">
                              <h4>Choose A Car</h4>
                              <p class="mb-0">Select from our diverse range of vehicles tailored to your needs.

</p>
                              <div class="setps-number">02.</div>
                          </div>
                      </div>
                      <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                          <div class="steps-item p-4 mb-4">
                              <h4>Enjoy Driving</h4>
                              <p class="mb-0">Embark on your journey with comfort and confidence, experiencing the joy of the road ahead.






</p>
                              <div class="setps-number">03.</div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
  
        

  
          <a href="#" class="btn btn-secondary btn-lg-square rounded-circle back-to-top"><i class="fa fa-arrow-up"></i></a>   
  
          
        </div>
        )
    
}

export default Home;