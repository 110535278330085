import React, { Component } from 'react';
import { Link } from 'react-router-dom';


// import ab1 from '../assets/images/LAND-CRUISER-SMK-removebg-preview.png';
import { Carousel } from 'react-responsive-carousel';


import Toyo51 from '../assets/images/moving-black-car-road.jpg';



import ab1 from '../asset/img/about-icon-1.png';
import ab2 from '../asset/img/about-icon-2.png';


import ab3 from '../asset/img/about-img.jpg';
import ab4 from '../asset/img/carousel-2-Copy.jpg';

import ban1 from '../asset/img/banner-1.jpg';

import car1 from '../asset/img/car-1.png';

import car2 from './images/x1.jpg';
import sli3 from '../assets/images/LAND-CRUISER-SMK-removebg-preview.png';

// import aboutBlog from '../assets/images/blog_1.jpg';
// import person1 from '../assets/images/person_1.jpg';
// import person2 from '../assets/images/person_2.jpg';
// import person3 from '../assets/images/person_3.jpg';
// import person4 from '../assets/images/person_4.jpg';

class About extends Component {
    state = {

    }
    componentDidMount(){
        window.scrollTo(0, 0)

    }
    render() {
        return (
            <div>
            
        <div class="container-fluid bg-breadcrumb">
            <div class="container text-center py-5" style={{maxWidth: "900px"}}>
                <h4 class="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">Our Services</h4>
                <ol class="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style={{background: "transparent"}}>
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item"><a href="#">Pages</a></li>
                    <li class="breadcrumb-item active text-primary">Services</li>
                </ol>    
            </div>
        </div>

      
        <div class="container-fluid service py-5">
              <div class="container py-5">
                  <div class="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                      <h1 class="display-5 text-capitalize mb-3">Car Rental <span class="text-primary">Services</span></h1>
                      <p class="mb-0">your premier destination for reliable and affordable car rental services in Karachi. With a wide selection of vehicles, competitive rates, and flexible rental options, we strive to meet all your transportation needs.
                      </p>
                  </div>
                  <div class="row g-4">
                      <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                          <div class="service-item p-4">
                              <div class="service-icon mb-4">
                                  <i class="fa fa-phone-alt fa-2x"></i>
                              </div>
                              <h5 class="mb-3">Phone Reservation</h5>
                              <p class="mb-0">Easily book your rental car with our convenient phone reservation service, available to assist you with your travel needs.

</p>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                          <div class="service-item p-4">
                              <div class="service-icon mb-4">
                                  <i class="fa fa-money-bill-alt fa-2x"></i>
                              </div>
                              <h5 class="mb-3">Special Rates</h5>
                              <p class="mb-0">Enjoy our special rates designed to provide you with affordable car rental options without compromising on quality.

</p>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                          <div class="service-item p-4">
                              <div class="service-icon mb-4">
                                  <i class="fa fa-road fa-2x"></i>
                              </div>
                              <h5 class="mb-3">One Way Rental</h5>
                              <p class="mb-0">Take advantage of our one way rental service, allowing you to pick up and drop off your rental car at different locations.

</p>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                          <div class="service-item p-4">
                              <div class="service-icon mb-4">
                                  <i class="fa fa-umbrella fa-2x"></i>
                              </div>
                              <h5 class="mb-3">24/7 Avaiable</h5>
                              <p class="mb-0">Our car rental services are available 24/7, ensuring that you have access to reliable transportation whenever you need it.

</p>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                          <div class="service-item p-4">
                              <div class="service-icon mb-4">
                                  <i class="fa fa-building fa-2x"></i>
                              </div>
                              <h5 class="mb-3">City to City</h5>
                              <p class="mb-0">Travel seamlessly from city to city with our reliable and comfortable car rental options, perfect for intercity journeys.

</p>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                          <div class="service-item p-4">
                              <div class="service-icon mb-4">
                                  <i class="fa fa-car-alt fa-2x"></i>
                              </div>
                              <h5 class="mb-3">Bullet Proof
                              </h5>
                              <p class="mb-0">Ensure your safety and peace of mind with our bulletproof car rental options, providing top-level protection for your travels.






</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="container-fluid counter bg-secondary py-5">
              <div class="container py-5">
                  <div class="row g-5">
                      <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
                          <div class="counter-item text-center">
                              <div class="counter-item-icon mx-auto">
                                  <i class="fas fa-thumbs-up fa-2x"></i>
                              </div>
                              <div class="counter-counting my-3">
                                  <span class="text-white fs-2 fw-bold" data-toggle="counter-up">1829</span>
                                  <span class="h1 fw-bold text-white">+</span>
                              </div>
                              <h4 class="text-white mb-0">Happy Clients</h4>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
                          <div class="counter-item text-center">
                              <div class="counter-item-icon mx-auto">
                                  <i class="fas fa-car-alt fa-2x"></i>
                              </div>
                              <div class="counter-counting my-3">
                                  <span class="text-white fs-2 fw-bold" data-toggle="counter-up">56</span>
                                  <span class="h1 fw-bold text-white">+</span>
                              </div>
                              <h4 class="text-white mb-0">Number of Cars</h4>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.5s">
                          <div class="counter-item text-center">
                              <div class="counter-item-icon mx-auto">
                                  <i class="fas fa-clock fa-2x"></i>
                              </div>
                              <div class="counter-counting my-3">
                                  <span class="text-white fs-2 fw-bold" data-toggle="counter-up">24/7</span>
                                  <span class="h1 fw-bold text-white">+</span>
                              </div>
                              <h4 class="text-white mb-0">Avaiable</h4>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.7s">
                          <div class="counter-item text-center">
                              <div class="counter-item-icon mx-auto">
                                  <i class="fas fa-building fa-2x"></i>
                              </div>
                              <div class="counter-counting my-3">
                                  <span class="text-white fs-2 fw-bold" data-toggle="counter-up">589</span>
                                  <span class="h1 fw-bold text-white">+</span>
                              </div>
                              <h4 class="text-white mb-0">Total kilometers</h4>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
  
          <div class="container-fluid feature py-5">
              <div class="container py-5">
                  <div class="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                      <h1 class="display-5 text-capitalize mb-3">Car Cental <br/> Karachi <span class="text-primary">Features</span></h1>
                      <p class="mb-0">Experience the best in car rental with our extensive fleet, competitive pricing, flexible rental plans, and exceptional 24/7 customer service in Karachi.







                      </p>
                  </div>
                  <div class="row g-4 align-items-center">
                      <div class="col-xl-4">
                          <div class="row gy-4 gx-0">
                              <div class="col-12 wow fadeInUp" data-wow-delay="0.1s">
                                  <div class="feature-item">
                                      <div class="feature-icon">
                                          <span class="fa fa-trophy fa-2x"></span>
                                      </div>
                                      <div class="ms-4">
                                          <h5 class="mb-3">First Class Services</h5>
                                          <p class="mb-0">Experience unmatched luxury and comfort with our First Class services, featuring gourmet meals, spacious seating, and personalized attention. Travel in style with an experience that elevates every journey.

</p>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-12 wow fadeInUp" data-wow-delay="0.3s">
                                  <div class="feature-item">
                                      <div class="feature-icon">
                                          <span class="fa fa-road fa-2x"></span>
                                      </div>
                                      <div class="ms-4">
                                          <h5 class="mb-3">24/7 Road Assistance</h5>
                                          <p class="mb-0">Enjoy peace of mind with our 24/7 Road Assistance, providing swift and reliable support for any roadside emergency. Travel confidently, knowing help is always just a call away.

</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12 col-xl-4 wow fadeInUp" data-wow-delay="0.2s">
                          <img src={sli3} class="img-fluid w-100" style={{objectFit: "cover"}} alt="Img"/>
                          {/* <img src="img/features-img.png" class="img-fluid w-100" style={{objectFit: "cover"}} alt="Img"/> */}
                      </div>
                      <div class="col-xl-4">
                          <div class="row gy-4 gx-0">
                              <div class="col-12 wow fadeInUp" data-wow-delay="0.1s">
                                  <div class="feature-item justify-content-end">
                                      <div class="text-end me-4">
                                          <h5 class="mb-3">Quality at Minimum</h5>
                                          <p class="mb-0">Get the best value for your money with our commitment to quality at minimum prices. Enjoy top-notch services and amenities without breaking the bank, ensuring a satisfying and affordable experience.
</p>
                                      </div>
                                      <div class="feature-icon">
                                          <span class="fa fa-tag fa-2x"></span>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-12 wow fadeInUp" data-wow-delay="0.3s">
                                  <div class="feature-item justify-content-end">
                                      <div class="text-end me-4">
                                          <h5 class="mb-3">Anytime Pick-Up & Drop-Off</h5>
                                          <p class="mb-0">Take advantage of our flexible anytime pick-up and drop-off services, designed to fit your schedule. Whether it's early morning or late night, we've got you covered for a seamless travel experience.






</p>
                                      </div>
                                      <div class="feature-icon">
                                          <span class="fa fa-map-pin fa-2x"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>


      <div class="container-fluid steps py-5">
              <div class="container py-5">
                  <div class="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                      <h1 class="display-5 text-capitalize text-white mb-3">Rental<span class="text-primary"> Process</span></h1>
                      <p class="mb-0 text-white">The rental process involves booking, documentation, vehicle pickup, use according to terms, and timely return, ensuring a seamless experience with clear billing and customer feedback for continual service improvement.







                      </p>
                  </div>
                  <div class="row g-4">
                      <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                          <div class="steps-item p-4 mb-4">
                              <h4>Come In Contact</h4>
                              <p class="mb-0">Connect with us easily to start your rental journey.

</p>
                              <div class="setps-number">01.</div>
                          </div>
                      </div>
                      <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                          <div class="steps-item p-4 mb-4">
                              <h4>Choose A Car</h4>
                              <p class="mb-0">Select from our diverse range of vehicles tailored to your needs.

</p>
                              <div class="setps-number">02.</div>
                          </div>
                      </div>
                      <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                          <div class="steps-item p-4 mb-4">
                              <h4>Enjoy Driving</h4>
                              <p class="mb-0">Embark on your journey with comfort and confidence, experiencing the joy of the road ahead.






</p>
                              <div class="setps-number">03.</div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>


<div class="container-fluid banner pb-5 wow zoomInDown" data-wow-delay="0.1s">
              <div class="container pb-5">
                  <div class="banner-item rounded">
                      <img src={ban1} class="img-fluid rounded w-100" alt=""/>
                      <div class="banner-content">
                          <h2 class="text-primary">Rent Your Car</h2>
                          <h1 class="text-white">Interested in Renting?</h1>
                          <p class="text-white">Don't hesitate and send us a message.</p>
                          <div class="banner-btn">
                              <a href="https://wa.me/923331263110?text=Hi *Car Rental Karachi*! I need more info about Car Rental Karachi https://carrentalkarachi.com" class="btn btn-secondary rounded-pill py-3 px-4 px-md-5 me-2" style={{color:"black"}}>WhatshApp</a>
                              <a href="+923331263110" class="btn btn-primary rounded-pill py-3 px-4 px-md-5 ms-2">Contact Us</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>



        
  
          














            {/* End About Page */}
            {/* Start Instagram Feed  */}
            
          </div>
        )
    }
}

export default About;