import React, { Component } from 'react';
import { Link } from 'react-router-dom';


// import ab1 from '../assets/images/LAND-CRUISER-SMK-removebg-preview.png';
import { Carousel } from 'react-responsive-carousel';


import Toyo51 from '../assets/images/moving-black-car-road.jpg';



import ab1 from '../asset/img/about-icon-1.png';
import ab2 from '../asset/img/about-icon-2.png';


import ab3 from '../asset/img/about-img.jpg';
import ab4 from '../asset/img/carousel-2-Copy.jpg';

import ban1 from '../asset/img/banner-1.jpg';

import car1 from '../asset/img/car-1.png';

import car2 from './images/x1.jpg';
import sli3 from '../assets/images/LAND-CRUISER-SMK-removebg-preview.png';

// import aboutBlog from '../assets/images/blog_1.jpg';
// import person1 from '../assets/images/person_1.jpg';
// import person2 from '../assets/images/person_2.jpg';
// import person3 from '../assets/images/person_3.jpg';
// import person4 from '../assets/images/person_4.jpg';

class About extends Component {
    state = {

    }
    componentDidMount(){
        window.scrollTo(0, 0)

    }
    render() {
        return (
            <div>
            
        <div class="container-fluid bg-breadcrumb">
            <div class="container text-center py-5" style={{maxWidth: "900px"}}>
                <h4 class="text-white display-4 mb-4 wow fadeInDown" data-wow-delay="0.1s">About Us</h4>
                <ol class="breadcrumb d-flex justify-content-center mb-0 wow fadeInDown" data-wow-delay="0.3s" style={{background: "transparent"}}>
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item"><a href="#">Pages</a></li>
                    <li class="breadcrumb-item active text-primary">About</li>
                </ol>    
            </div>
        </div>

        <div class="container-fluid overflow-hidden about py-5">
              <div class="container py-5">
                  <div class="row g-5">
                      <div class="col-xl-6 wow fadeInLeft" data-wow-delay="0.2s">
                          <div class="about-item">
                              <div class="pb-5">
                                  <h1 class="display-5 text-capitalize">Car Rental <span class="text-primary">About</span></h1>
                                  <p class="mb-0">Welcome to Car Rental Pakistan, your premier destination for reliable and affordable car rental services in Karachi. With a wide selection of vehicles, competitive rates, and flexible rental options, we strive to meet all your transportation needs.
                                  Book with us today and discover the convenience and quality that set us apart.
                                  </p>
                              </div>
                              <div class="row g-4">
                                  <div class="col-lg-6">
                                      <div class="about-item-inner border p-4">
                                          <div class="about-icon mb-4">
                                              <img src={ab1} class="img-fluid w-50 h-50" alt="Icon"/>
                                          </div>
                                          <h5 class="mb-3">Our Vision</h5>
                                          <p class="mb-0">To become Karachi's leading car rental provider by delivering superior service, and innovative transportation solutions.

</p>
                                      </div>
                                  </div>
                                  <div class="col-lg-6">
                                      <div class="about-item-inner border p-4">
                                          <div class="about-icon mb-4">
                                              <img src={ab2} class="img-fluid h-50 w-50" alt="Icon"/>
                                          </div>
                                          <h5 class="mb-3">Our Mision</h5>
                                          <p class="mb-0">To offer a  enjoyable car rental experience with a diverse fleet and 24/7 support, ensuring customer satisfaction and convenience.






</p>
                                      </div>
                                  </div>
                              </div>
                              <p class="text-item my-4">Whether you're traveling for business, leisure, or special occasions, Car Rental Pakistan is here to make your journey smooth and enjoyable.
                              </p>
                              <div class="row g-4">
                                  <div class="col-lg-6">
                                      <div class="text-center rounded bg-secondary p-4">
                                          <h1 class="display-6 text-white">17</h1>
                                          <h5 class="text-light mb-0">Years Of Experience</h5>
                                      </div>
                                  </div>
                                  <div class="col-lg-6">
                                      <div class="rounded">
                                          <p class="mb-2"><i class="fa fa-check-circle text-primary me-1"></i> Wide Range of Vehicles: </p>
                                          <p class="mb-2"><i class="fa fa-check-circle text-primary me-1"></i> Affordable Rates:</p>
                                          <p class="mb-2"><i class="fa fa-check-circle text-primary me-1"></i> 24/7 Customer Support: </p>
                                          <p class="mb-0"><i class="fa fa-check-circle text-primary me-1"></i> Flexible Rental Options:</p>
                                      </div>
                                  </div>
                                  <div class="col-lg-5 d-flex align-items-center">
                                      <a href="#" class="btn btn-primary rounded py-3 px-5">More About Us</a>
                                  </div>
                                  {/* <div class="col-lg-7">
                                      <div class="d-flex align-items-center">
                                          <img src="img/attachment-img.jpg" class="img-fluid rounded-circle border border-4 border-secondary" style={{width: '100px', height: "100px"}} alt="Image"/>
                                          <div class="ms-4">
                                              <h4>William Burgess</h4>
                                              <p class="mb-0">Carveo Founder</p>
                                          </div>
                                      </div>
                                  </div> */}
                              </div>
                          </div>
                      </div>
                      <div class="col-xl-6 wow fadeInRight" data-wow-delay="0.2s">
                          <div class="about-img">
                              <div class="img-1">
                                  <img src={ab3} class="img-fluid rounded h-100 w-100" alt=""/>
                              </div>
                              <div class="img-2">
                                  <img src={ab4} class="img-fluid rounded w-100" alt=""/>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

 <div class="container-fluid counter bg-secondary py-5">
              <div class="container py-5">
                  <div class="row g-5">
                      <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
                          <div class="counter-item text-center">
                              <div class="counter-item-icon mx-auto">
                                  <i class="fas fa-thumbs-up fa-2x"></i>
                              </div>
                              <div class="counter-counting my-3">
                                  <span class="text-white fs-2 fw-bold" data-toggle="counter-up">1829</span>
                                  <span class="h1 fw-bold text-white">+</span>
                              </div>
                              <h4 class="text-white mb-0">Happy Clients</h4>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
                          <div class="counter-item text-center">
                              <div class="counter-item-icon mx-auto">
                                  <i class="fas fa-car-alt fa-2x"></i>
                              </div>
                              <div class="counter-counting my-3">
                                  <span class="text-white fs-2 fw-bold" data-toggle="counter-up">56</span>
                                  <span class="h1 fw-bold text-white">+</span>
                              </div>
                              <h4 class="text-white mb-0">Number of Cars</h4>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.5s">
                          <div class="counter-item text-center">
                              <div class="counter-item-icon mx-auto">
                                  <i class="fas fa-clock fa-2x"></i>
                              </div>
                              <div class="counter-counting my-3">
                                  <span class="text-white fs-2 fw-bold" data-toggle="counter-up">24/7</span>
                                  <span class="h1 fw-bold text-white">+</span>
                              </div>
                              <h4 class="text-white mb-0">Avaiable</h4>
                          </div>
                      </div>
                      <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.7s">
                          <div class="counter-item text-center">
                              <div class="counter-item-icon mx-auto">
                                  <i class="fas fa-building fa-2x"></i>
                              </div>
                              <div class="counter-counting my-3">
                                  <span class="text-white fs-2 fw-bold" data-toggle="counter-up">589</span>
                                  <span class="h1 fw-bold text-white">+</span>
                              </div>
                              <h4 class="text-white mb-0">Total kilometers</h4>
                          </div>
                      </div>
                  </div>
              </div>
          </div>


          <div class="container-fluid feature py-5">
              <div class="container py-5">
                  <div class="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                      <h1 class="display-5 text-capitalize mb-3">Car Rental <br/> Pakistan <span class="text-primary">Features</span></h1>
                      <p class="mb-0">Experience the best in car rental with our extensive fleet, competitive pricing, flexible rental plans, and exceptional 24/7 customer service in Karachi.







                      </p>
                  </div>
                  <div class="row g-4 align-items-center">
                      <div class="col-xl-4">
                          <div class="row gy-4 gx-0">
                              <div class="col-12 wow fadeInUp" data-wow-delay="0.1s">
                                  <div class="feature-item">
                                      <div class="feature-icon">
                                          <span class="fa fa-trophy fa-2x"></span>
                                      </div>
                                      <div class="ms-4">
                                          <h5 class="mb-3">First Class Services</h5>
                                          <p class="mb-0">Experience unmatched luxury and comfort with our First Class services, featuring gourmet meals, spacious seating, and personalized attention. Travel in style with an experience that elevates every journey.

</p>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-12 wow fadeInUp" data-wow-delay="0.3s">
                                  <div class="feature-item">
                                      <div class="feature-icon">
                                          <span class="fa fa-road fa-2x"></span>
                                      </div>
                                      <div class="ms-4">
                                          <h5 class="mb-3">24/7 Road Assistance</h5>
                                          <p class="mb-0">Enjoy peace of mind with our 24/7 Road Assistance, providing swift and reliable support for any roadside emergency. Travel confidently, knowing help is always just a call away.

</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-12 col-xl-4 wow fadeInUp" data-wow-delay="0.2s">
                          <img src={sli3} class="img-fluid w-100" style={{objectFit: "cover"}} alt="Img"/>
                          {/* <img src="img/features-img.png" class="img-fluid w-100" style={{objectFit: "cover"}} alt="Img"/> */}
                      </div>
                      <div class="col-xl-4">
                          <div class="row gy-4 gx-0">
                              <div class="col-12 wow fadeInUp" data-wow-delay="0.1s">
                                  <div class="feature-item justify-content-end">
                                      <div class="text-end me-4">
                                          <h5 class="mb-3">Quality at Minimum</h5>
                                          <p class="mb-0">Get the best value for your money with our commitment to quality at minimum prices. Enjoy top-notch services and amenities without breaking the bank, ensuring a satisfying and affordable experience.
</p>
                                      </div>
                                      <div class="feature-icon">
                                          <span class="fa fa-tag fa-2x"></span>
                                      </div>
                                  </div>
                              </div>
                              <div class="col-12 wow fadeInUp" data-wow-delay="0.3s">
                                  <div class="feature-item justify-content-end">
                                      <div class="text-end me-4">
                                          <h5 class="mb-3">Anytime Pick-Up & Drop-Off</h5>
                                          <p class="mb-0">Take advantage of our flexible anytime pick-up and drop-off services, designed to fit your schedule. Whether it's early morning or late night, we've got you covered for a seamless travel experience.






</p>
                                      </div>
                                      <div class="feature-icon">
                                          <span class="fa fa-map-pin fa-2x"></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>


      <div class="container-fluid steps py-5">
              <div class="container py-5">
                  <div class="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                      <h1 class="display-5 text-capitalize text-white mb-3">Rental<span class="text-primary"> Process</span></h1>
                      <p class="mb-0 text-white">The rental process involves booking, documentation, vehicle pickup, use according to terms, and timely return, ensuring a seamless experience with clear billing and customer feedback for continual service improvement.







                      </p>
                  </div>
                  <div class="row g-4">
                      <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                          <div class="steps-item p-4 mb-4">
                              <h4>Come In Contact</h4>
                              <p class="mb-0">Connect with us easily to start your rental journey.

</p>
                              <div class="setps-number">01.</div>
                          </div>
                      </div>
                      <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                          <div class="steps-item p-4 mb-4">
                              <h4>Choose A Car</h4>
                              <p class="mb-0">Select from our diverse range of vehicles tailored to your needs.

</p>
                              <div class="setps-number">02.</div>
                          </div>
                      </div>
                      <div class="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                          <div class="steps-item p-4 mb-4">
                              <h4>Enjoy Driving</h4>
                              <p class="mb-0">Embark on your journey with comfort and confidence, experiencing the joy of the road ahead.






</p>
                              <div class="setps-number">03.</div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>

        {/* <div class="container-fluid team py-5">
            <div class="container py-5">
                <div class="text-center mx-auto pb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "800px"}}>
                    <h1 class="display-5 text-capitalize mb-3">Customer<span class="text-primary"> Suport</span> Center</h1>
                    <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ut amet nemo expedita asperiores commodi accusantium at cum harum, excepturi, quia tempora cupiditate! Adipisci facilis modi quisquam quia distinctio,
                    </p>
                </div>
                <div class="row g-4">
                    <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
                        <div class="team-item p-4 pt-0">
                            <div class="team-img">
                                <img src="img/team-1.jpg" class="img-fluid rounded w-100" alt="Image"/>
                            </div>
                            <div class="team-content pt-4">
                                <h4>MARTIN DOE</h4>
                                <p>Profession</p>
                                <div class="team-icon d-flex justify-content-center">
                                    <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                                    <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-twitter"></i></a>
                                    <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-instagram"></i></a>
                                    <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
                        <div class="team-item p-4 pt-0">
                            <div class="team-img">
                                <img src="img/team-2.jpg" class="img-fluid rounded w-100" alt="Image"/>
                            </div>
                            <div class="team-content pt-4">
                                <h4>MARTIN DOE</h4>
                                <p>Profession</p>
                                <div class="team-icon d-flex justify-content-center">
                                    <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                                    <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-twitter"></i></a>
                                    <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-instagram"></i></a>
                                    <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.5s">
                        <div class="team-item p-4 pt-0">
                            <div class="team-img">
                                <img src="img/team-3.jpg" class="img-fluid rounded w-100" alt="Image"/>
                            </div>
                            <div class="team-content pt-4">
                                <h4>MARTIN DOE</h4>
                                <p>Profession</p>
                                <div class="team-icon d-flex justify-content-center">
                                    <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                                    <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-twitter"></i></a>
                                    <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-instagram"></i></a>
                                    <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3 wow fadeInUp" data-wow-delay="0.7s">
                        <div class="team-item p-4 pt-0">
                            <div class="team-img">
                                <img src="img/team-4.jpg" class="img-fluid rounded w-100" alt="Image"/>
                            </div>
                            <div class="team-content pt-4">
                                <h4>MARTIN DOE</h4>
                                <p>Profession</p>
                                <div class="team-icon d-flex justify-content-center">
                                    <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                                    <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-twitter"></i></a>
                                    <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-instagram"></i></a>
                                    <a class="btn btn-square btn-light rounded-circle mx-1" href=""><i class="fab fa-linkedin-in"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

<div class="container-fluid banner pb-5 wow zoomInDown" data-wow-delay="0.1s">
              <div class="container pb-5">
                  <div class="banner-item rounded">
                      <img src={ban1} class="img-fluid rounded w-100" alt=""/>
                      <div class="banner-content">
                          <h2 class="text-primary">Rent Your Car</h2>
                          <h1 class="text-white">Interested in Renting?</h1>
                          <p class="text-white">Don't hesitate and send us a message.</p>
                          <div class="banner-btn">
                              <a href="https://wa.me/923331263110?text=Hi *Car Rental Pakistan*! I need more info about Car Rental Karachi https://rentalcarspk.site" class="btn btn-secondary rounded-pill py-3 px-4 px-md-5 me-2" style={{color:"black"}}>WhatshApp</a>
                              <a href="+923331263110" class="btn btn-primary rounded-pill py-3 px-4 px-md-5 ms-2">Contact Us</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>



          

<div className='container' style={{margin : "30px 10px"}}>
            <div className='row'  >
            <div className="col-10 col-lg-10  col-sm-12" style={{margin  :"auto"}}>
 
                 <h2>Welcome to Car Rental Karachi</h2>

<p>Your premier destination for top-notch rental car services. Discover the perfect blend of style and reliability with our diverse fleet, featuring renowned models such as the Toyota Corolla and Honda HR-V. As your trusted partner in transportation, we are committed to delivering an unparalleled experience that combines comfort, convenience, and excellence.</p>

<h2>Our Mission</h2>

<p>At Car Rental Karachi, our mission is to redefine the rental car experience through a seamless fusion of quality service and customer satisfaction. Whether it's a business trip or a family vacation, our dedication to providing exceptional services ensures that every journey is not just a trip but a memorable adventure.</p>

<h2>Fleet Excellence</h2>

<p>Experience the pinnacle of performance with our meticulously maintained fleet, showcasing the efficiency of the Toyota Corolla and the style of the Honda HR-V. Our commitment to excellence extends to every vehicle, guaranteeing a smooth and enjoyable ride. Choose Car Rental Karachi for a journey that reflects our passion for quality and precision.</p>

<h2>Pickup and Drop-off Services</h2>

<p>Embrace the convenience of our tailored pickup and drop-off services. At Car Rental Karachi, we understand the value of your time, and our services are designed to make your experience seamless. Whether you prefer doorstep delivery or choose to pick up your vehicle at our location, our flexible options cater to your schedule, ensuring a stress-free rental experience.</p>

<h2>Rental Solutions</h2>

<p>Unlock a world of possibilities with our rental solutions. From short-term daily rentals to long-term leases, Car Rental Karachi provides customizable packages to suit your unique needs. Our commitment to versatility ensures that you have the perfect vehicle for every occasion, allowing you to focus on the journey ahead.</p>

<p>Choose Car Rental Karachi for a rental experience that goes beyond expectations. With our dedication to excellence, a diverse and well-maintained fleet, and services optimized for your convenience, we are your ultimate destination for top-tier transportation solutions. Book with us today and elevate your travel experience to new heights.</p>

            </div>
            </div>
            </div>


            {/* End About Page */}
            {/* Start Instagram Feed  */}
            
          </div>
        )
    }
}

export default About;